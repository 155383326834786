<script setup lang="ts">
const { checkMultibrandData, fetchKioskMetadata } = useSettingsStore();

async function setupKiosk() {
  // Set multibrand values
  checkMultibrandData();
  // Check metadata
  await fetchKioskMetadata();
}

await setupKiosk();
</script>

<template>
  <router-view />
</template>
