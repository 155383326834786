<script setup lang="ts">
const route = useRoute();
const cartUtils = useCartUtils();
const menuController = useMenuController();
const languageStore = useLanguageStore();
const metadataStore = useMetadataStore();
const inactiveStoreV2 = useInactiveStoreV2();
const settingsStore = useSettingsStore();

const { triggerCancelIdleModal, triggerStartOrder } = useGTMEventsComposableV2();
const { gtmTransactionId } = storeToRefs(settingsStore);

function cancelInactivityModal() {
  inactiveStoreV2.resetInactivity();
  // Trigger GTM event
  triggerCancelIdleModal(route.name);
}

/**
 * Activate the inactive modal
 */
async function beforeMount() {
  // Get menu
  const { storeId } = route.params;
  if (!storeId) return;
  await menuController.getMenu(storeId as string, languageStore.currentLanguage);

  // Generate transactionId
  gtmTransactionId.value = new Date().getTime().toString();
  triggerStartOrder();

  // Start countdown
  if (!metadataStore.inactivity) return;

  inactiveStoreV2.setup({
    displayModalSeconds: metadataStore.inactivity.screen,
    inactivitySeconds: metadataStore.inactivity.timer,
  });
}

function beforeUnmount() {
  cartUtils.restartCart();
  inactiveStoreV2.pauseChecking();
}

onBeforeMount(() => beforeMount());
onBeforeUnmount(() => beforeUnmount());

function pressed() {
  inactiveStoreV2.resetInactivity();
}
</script>

<template>
  <div
    @mousedown="pressed"
    @touchstart="pressed"
    @mouseup="pressed"
    @mouseleave="pressed"
    @touchend="pressed"
    @touchcancel="pressed"
    @blur="pressed"
  >
    <router-view />
  </div>
  <Teleport to="#inactive-modal">
    <OrderInactivityModal
      v-if="inactiveStoreV2.displayModal"
      :time="inactiveStoreV2.modalCounter"
      @inactive="cartUtils.restartCart(true)"
      @close="cancelInactivityModal()"
    />
  </Teleport>
</template>
