<script lang="ts" setup>
import { PropType } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel, Navigation } from 'swiper/modules';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import noPhotoSrc from '@/assets/images/noPhoto.jpeg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { RouteName } from '@/common/routes/routeName';

defineProps({
  menuItems: {
    type: Array as PropType<HubsterCategory[]>,
    required: true,
  },
  selectedCategory: {
    type: Object as PropType<HubsterCategory>,
    required: false,
    default: () => ({}),
  },
});

defineEmits<{
  selectCategory: [category: HubsterCategory],
}>();

const route = useRoute();
const router = useRouter();
const metadataStore = useMetadataStore();
const { triggerGoBack } = useGTMEventsComposableV2();
const { integration, mainLogo } = storeToRefs(metadataStore);

function goBack() {
  router.push({ name: metadataStore.hideCategories ? RouteName.ORDER_TYPE : RouteName.ORDER_CATEGORIES });
  // Trigger GTM event
  triggerGoBack(route.name);
}
</script>

<template>
  <nav class="sidebar">
    <button type="button" @click="goBack()">
      <KioskImage
        v-if="mainLogo"
        :src="mainLogo"
        class="mb-10 logo"
        hide-border
        :alt="`${integration} logo`"
      />
    </button>

    <button type="button" class="prev-button-class top-animation">
      <span class="text-5xl icon icon-arrow-up" />
    </button>

    <Swiper
      v-if="menuItems.length > 0"
      ref="sliderEl"
      :direction="'vertical'"
      :modules="[Navigation, Mousewheel]"
      :slides-per-view="6"
      :navigation="{
        enabled: true,
        prevEl: '.prev-button-class',
        nextEl: '.next-button-class',
      }"
      :mousewheel="true"
      class="w-full h-[1500px]"
    >
      <SwiperSlide
        v-for="item in menuItems"
        :key="item.id"
      >
        <button
          type="button"
          class="menu-item-button"
          :class="selectedCategory?.id === item.id ? 'active' : ''"
          @click="$emit('selectCategory', item)"
        >
          <KioskImage
            image-class="object-cover object-center w-40 h-40 rounded-md"
            :src="item.description || noPhotoSrc"
            :alt="item.name"
          />
          <span class="mt-2 text-3xl leading-none text-center">{{ item.name }}</span>
        </button>
      </SwiperSlide>
    </Swiper>

    <button type="button" class="next-button-class bottom-animation">
      <span class="text-5xl icon icon-arrow-down" />
    </button>
  </nav>
</template>

<style scoped>
.logo {
@apply w-40 h-40 max-w-none mb-8;
}

.menu-item-button {
  @apply w-full flex flex-col items-center justify-center p-3;
}

.top-animation {
  @apply animate-bounce;
}

.active {
  @apply bg-neutral-200 rounded-xs font-bold;
}

.sidebar {
  @apply flex flex-col relative justify-start items-center py-8 px-3 bg-white z-10 h-full w-56;
}
</style>
