<script setup lang="ts">
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { selectedItems } from '@/utils/modifierSelectedItems';

const metadataStore = useMetadataStore();
const orderTypeStore = useOrderTypeStore();
const menuStore = useMenuStore();
const cartStoreV2 = useCartStoreV2();
const couponStore = useCouponStore();
const { openCurrencyModal } = useCurrencyModal();

const { createOrder } = useCreateOrder();

const { orderType } = storeToRefs(orderTypeStore);
const { productsCurrency } = storeToRefs(menuStore);
const {
  hideCash,
  hideCard,
  paymentImage,
  priceDecimals,
  clusterSettings,
} = storeToRefs(metadataStore);

const cartItems = computed(() => cartStoreV2.items.map((item) => item.item));
const orderTotal = computed((): Partial<HubsterOrderTotal> => {
  const { subtotal, taxes } = cartStoreV2;

  const total = subtotal - couponStore.discount;
  const subtotalWithoutTaxes = subtotal - taxes;

  return {
    subtotal: taxes === 0 ? subtotal : subtotalWithoutTaxes,
    tax: taxes,
    discount: couponStore.discount,
    total,
  };
});

defineProps({
  customer: {
    type: Object,
    required: true,
  },
});

const currencyValue = (value: number) =>
  formatCurrency(productsCurrency.value, value, priceDecimals.value);

function createCashOrder() {
  createOrder(PaymentMethod.CASH);
}

function createCardOrder() {
  if (!clusterSettings.value?.basics.pinpad.pinpadBrand) {
    createOrder(PaymentMethod.CARD);
    return;
  }

  const { conversions } = clusterSettings.value.currency;
  const { pinpadBrand } = clusterSettings.value.basics.pinpad;
  const isConversionEnabled = CLOUD_PINPADS.includes(pinpadBrand);
  if (isConversionEnabled && conversions?.length > 0) {
    openCurrencyModal();
    return;
  }

  createOrder(PaymentMethod.CARD);
}
</script>
<template>
  <section
    class="flex flex-col items-center justify-center pb-32 w-[600px] mx-auto mt-20 relative"
  >
    <div
      class="invoice-border bg-gradient-to-b from-primary-600 to-primary-800 w-[810px] h-40 left-1/2 rounded-md flex items-center justify-center px-16 absolute z-0 -translate-x-1/2 -top-14"
    >
      <div class="w-full h-20 p-4 dark-border bg-primary-900">
        <div class="w-full h-12 bg-black" />
      </div>
    </div>

    <div class="relative z-10 bg-white summary-card">
      <div class="flex flex-col gap-2">
        <h2 class="text-[32px] leading-6 tracking-tight font-bold">
          {{ customer.name }}
        </h2>
        <span class="order-type">{{ orderType }}</span>
      </div>

      <table class="max-h-[650px] overflow-scroll">
        <thead>
          <tr class="table-header">
            <th class="text-start">
              {{ $t("ITEMS") }}
            </th>
            <th class="text-center">
              {{ $t("QUANTITY") }}
            </th>
            <th class="text-end">
              {{ $t("PRICE") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <template :key="item.itemMeta.id" v-for="item in cartItems">
            <tr class="table-columns">
              <td class="pt-4 pb-2 text-start">
                {{ item.itemMeta.name }}
              </td>
              <td class="pt-4 pb-2 text-center">
                {{ item.quantity }}
              </td>
              <td class="pt-4 pb-2 text-end">
                {{ currencyValue(item.itemMeta.price.amount) }}
              </td>
            </tr>

            <OrderCheckoutRowV2
              v-for="child in selectedItems(item.modifiers)"
              :key="child.itemMeta.id"
              :item="child"
              :is-child-row="false"
              :currency="productsCurrency"
              :decimals="priceDecimals"
            />
          </template>
        </tbody>
      </table>

      <div class="divider w-[575px] -bottom-7" />
    </div>

    <!-- PriceSummary -->
    <div
      class="flex flex-col overflow-hidden summary-total"
      :class="{ '!h-40': orderTotal.tax, '': orderTotal.discount }"
    >
      <template v-if="orderTotal.subtotal !== orderTotal.total">
        <div
          class="flex justify-between w-full h-8 font-normal text-neutral-400"
        >
          <span class="w-5/6 text-center">{{ $t("SUBTOTAL") }}</span>
          <span>{{ currencyValue(orderTotal.subtotal!) }}</span>
        </div>
      </template>

      <template v-if="orderTotal.tax">
        <div
          class="flex justify-between w-full h-8 font-normal text-neutral-400"
        >
          <span class="w-5/6 text-center">{{ $t("TAXES") }}</span>
          <span>{{ currencyValue(orderTotal.tax) }}</span>
        </div>
      </template>

      <template v-if="orderTotal.discount">
        <div
          class="flex justify-between w-full h-8 font-normal text-neutral-400"
        >
          <span class="w-5/6 text-center">{{ $t("DISCOUNTS") }}</span>
          <span>-{{ currencyValue(orderTotal.discount) }}</span>
        </div>
      </template>

      <div class="flex justify-between w-full">
        <span class="w-5/6 text-center">{{ $t("TOTAL") }}</span>
        <span>{{ currencyValue(orderTotal.total!) }}</span>
      </div>
      <div class="divider w-[590px] -bottom-[-6.25rem]" />
    </div>
  </section>

  <div class="payment-options">
    <div class="option" v-if="!hideCash">
      <span class="block my-6 text-4xl text-center">{{
        $t("PAY_ON_SITE")
      }}</span>

      <KioskButton icon="cash" color="outline" @click="createCashOrder()">
        {{ $t("CASH") }}
      </KioskButton>
    </div>

    <div class="option" v-if="!hideCard">
      <KioskImage
        v-if="paymentImage"
        :src="paymentImage"
        alt="Payment image"
        hide-border
        class="w-full mx-auto"
        loader-size="small"
      />

      <KioskButton
        icon="credit-card"
        color="primary"
        class="w-full mx-auto"
        @click="createCardOrder()"
      >
        {{ $t("CARD") }}
      </KioskButton>
    </div>
  </div>
</template>

<style scoped>
.summary-card {
  @apply shadow-neutral-400/30 shadow-2xl;
  @apply p-12 border border-neutral-500/50 rounded-md rounded-t-none flex flex-col gap-8 w-[600px] min-h-[500px];
}

.order-type {
  @apply text-2xl leading-5 tracking-[0.5px];
}

.summary-total {
  @apply shadow-neutral-400/30 shadow-2xl z-20 bg-white border-b-0 py-3;
  @apply px-12 font-medium border flex justify-center items-center w-[600px] h-fit text-3xl border-t-0 border-neutral-500/50 rounded-t-md;
}

.header-item {
  @apply text-2xl font-medium leading-8 tracking-tight text-neutral-400;
}

.summary-line {
  @apply w-[556px] border;
}

.table-header > th {
  @apply text-2xl font-medium leading-8 tracking-tight text-neutral-400 pb-5;
}

.table-columns > td {
  @apply text-2xl leading-7 tracking-[0.5px];
}

.payment-options {
  @apply flex items-end justify-center w-full px-32 mt-24;
}

.payment-options .option {
  @apply flex flex-col justify-center w-1/2 mx-5;
}

.divider {
  @apply absolute z-40 left-1/2 -translate-x-1/2 border-t-8 border-spacing-0 border-neutral-500/80 border-dotted;
}

</style>
