import { createI18n } from 'vue-i18n';
// Language dictionaries
import { en, es, fr, pt } from '@/locales';

export const i18n = createI18n({
  legacy: false,
  locale: 'es',
  messages: {
    en,
    es,
    fr,
    pt,
  },
});

export default i18n;
