/* eslint-disable no-param-reassign */
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

export function uniqueItemPatcher(item: ItemDescriptor): void {
  if (!item.parent || !item.parent?.isRequired) return;

  item.quantity = item.parent.dynamicMinimumSelections;
  item.disableAddButton = true;
  item.disableSubButton = true;
}
