export function useCartUtils() {
  const route = useRoute();
  const cartStore = useCartStoreV2();
  const webhookStore = useWebhookStoreV2();
  const settingsStore = useSettingsStore();
  const { triggerCancelOrder } = useGTMEventsComposableV2();

  const { gtmTransactionId } = storeToRefs(settingsStore);

  /**
   * Reset the cart and redirect to first view
   */
  const restartCart = (isModal = false) => {
    triggerCancelOrder({
      items: toGTMCartItems(cartStore.items),
      section: route.name!.toString(),
      isModal,
    });

    cartStore.clearCart();
    webhookStore.restartWebhook();

    if (settingsStore.multibrandData) {
      const { backUrl } = settingsStore.multibrandData;
      window.location.href = backUrl;
      return;
    }

    goBackToHome();
    // Reset transactionId
    gtmTransactionId.value = '';
  };

  return { restartCart };
}
