/* eslint-disable max-lines-per-function */
import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { IOrderOption } from '@/modules/order-type/types';

export const useOrderTypeStore = defineStore('orderType', () => {
  const { t } = useI18n();
  const { triggerAddTableNumber } = useGTMEventsComposableV2();
  // State
  const tableNumber = ref<string>('');
  const orderTypes = ref<IOrderOption[]>([]);
  const fulfillmentMode = ref<FulfillmentMode>(FulfillmentMode.DINE_IN);

  // Getters
  const isPickupOrder = computed(() => fulfillmentMode.value === FulfillmentMode.PICKUP);

  const orderType = computed(() => {
    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return t('TAKE_AWAY');
    }

    return tableNumber.value ? `${t('EAT_HERE')} - ${t('TABLE')} ${tableNumber.value}` : t('EAT_HERE');
  });

  const orderOptions = computed(() => orderTypes.value.map((type) => ({
    ...type,
    label: t(type.label),
  })));

  // Actions
  function setOrderOptions(value: IOrderOption[]) {
    orderTypes.value = value;
  }

  function setTableNumber(value: string) {
    tableNumber.value = value.toString();
    // Trigger GTM event
    if (value.length > 0) triggerAddTableNumber(value);
  }

  return {
    orderTypes,
    orderOptions,
    orderType,
    tableNumber,
    fulfillmentMode,
    isPickupOrder,
    setOrderOptions,
    setTableNumber,
  };
});
