import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

export const useCartItemHandler = () => {
  const cartStoreV2 = useCartStoreV2();
  const { triggerAddToCart, triggerRemoveFromCart } = useGTMEventsComposableV2();
  const route = useRoute();

  function handleGTMEvent({
    item,
    cartItemIndex,
    decrease,
  }: {
    item: ItemDescriptor;
    cartItemIndex: number;
    decrease?: boolean;
  }) {
    if (decrease) {
      triggerRemoveFromCart({
        product: {
          id: item.itemMeta.id,
          name: item.itemMeta.name,
          price: item.total,
          quantity: item.quantity,
        },
        description: cartStoreV2.items[cartItemIndex].description,
        category: item.category,
        removedQuantity: 1,
        identifier: cartStoreV2.items[cartItemIndex].identifier,
      });
      return;
    }

    triggerAddToCart({
      item: {
        ...toGTMCartItem(cartStoreV2.items[cartItemIndex]),
        quantity: 1,
      },
      section: route.name,
      operation: CartEventType.ADD,
    });
  }

  function increaseCartItemQuantity(
    item: ItemDescriptor,
    cartItemIndex: number,
  ) {
    cartStoreV2.increaseItemQuantity(item);
    handleGTMEvent({ item, cartItemIndex });
  }

  function decreaseCartItemQuantity(
    item: ItemDescriptor,
    cartItemIndex: number,
  ) {
    handleGTMEvent({ item, cartItemIndex, decrease: true });
    cartStoreV2.decreaseItemQuantity(item);
  }

  function removeItem(item: ItemDescriptor, cartItemIndex: number) {
    const { description, identifier } = cartStoreV2.items[cartItemIndex];
    triggerRemoveFromCart({
      product: {
        id: item.itemMeta.id,
        name: item.itemMeta.name,
        price: item.total,
        quantity: item.quantity,
      },
      description,
      identifier,
      category: item.category,
      removedQuantity: item.quantity,
    });
    cartStoreV2.removeItem(item);
  }

  return {
    increaseCartItemQuantity,
    decreaseCartItemQuantity,
    removeItem,
  };
};
