<script lang="ts" setup>
import { useMutationObserver, useScroll } from '@vueuse/core';
import { PropType } from 'vue';
import { FadeColors } from '../enums/fadeColors';

const contentRef = ref<HTMLElement>();
const { arrivedState, measure } = useScroll(contentRef);

useMutationObserver(contentRef, (mutations) => {
  if (mutations[0]) {
    measure();
  }
}, {
  childList: true,
  subtree: true,
});

defineProps({
  height: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: true,
  },
  color: {
    type: String as PropType<FadeColors>,
    default: FadeColors.WHITE,
  },
  twClass: {
    type: String,
    default: '',
  },
});

</script>

<template>
  <section
    :class="['relative', twClass]"
    :style="{
      height,
      width,
    }"
  >
    <FadeScroll :color="color" position="top" :show="!arrivedState.top" />

    <div ref="contentRef" class="w-full h-full max-h-[inherit] overflow-y-auto">
      <slot />
    </div>

    <FadeScroll :color="color" position="bottom" :show="!arrivedState.bottom" />
  </section>
</template>
