export function useTotal() {
  const menu = useMenuStore();
  const metadataStore = useMetadataStore();

  const { productsCurrency } = storeToRefs(menu);
  const { priceDecimals } = storeToRefs(metadataStore);

  function getFormatPrice(total: number) {
    const price = formatCurrency(
      productsCurrency.value,
      total,
      priceDecimals.value,
    );

    return price;
  }

  return {
    getFormatPrice,
  };
}
