export function printHtml(textHtml: string) {
  const WinPrint = window.open('about:blank', '_new', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

  if (!WinPrint) return;

  WinPrint.document.write(textHtml);

  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.addEventListener('load', () => {
    WinPrint.print();
    WinPrint.close();
  });
}
