<script lang="ts" setup>
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

const { adBanners, onBannerClick } = useRandomBanner();

</script>

<template>
  <!-- <button
    v-if="randomUpSellingBanner"
    class="w-full"
    type="button"
    @click="onBannerClick"
  >
    <img
      class="object-cover w-full rounded"
      :src="randomUpSellingBanner.photoIds[1]"
      :alt="randomUpSellingBanner.name"
    />
  </button> -->
  <Swiper
    :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
    }"
    :modules="[Autoplay]"
  >
    <SwiperSlide
      v-for="banner in adBanners"
      :key="banner.id"
    >
      <button class="w-full h-64" type="button" @click="onBannerClick(banner)">
        <KioskImage
          :src="banner.photoIds[1]"
          :image-class="`object-cover w-full h-full object-center`"
          hide-border
        />
      </button>
    </SwiperSlide>
  </Swiper>
</template>
