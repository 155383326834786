import { autoSelectItems,
  ModifierDescriptor } from '@slabcode/hubster-modifiers-utils';
import { uniqueItemPatcher } from './uniqueItemPatcher';

export function prepareModifiers({
  modifiers,
  autoSelect = true,
  applySingleModifierPatch = true,
  applySingleItemPatch = true,
}: {
  modifiers: ModifierDescriptor[];
  autoSelect?: boolean;
  applySingleModifierPatch?: boolean;
  applySingleItemPatch?: boolean;
}): void {
  modifiers.forEach((m) => {
    if (autoSelect) autoSelectItems(m);
    if (applySingleModifierPatch) m.items.forEach((_item) => uniqueModifierPatcher(_item));

    if (applySingleItemPatch && m.items.length === 1) {
      const [item] = m.items;
      uniqueItemPatcher(item);
    }
  });
}
