import { ItemDescriptor,
  quantifiable,
  selectable } from '@slabcode/hubster-modifiers-utils';
import { ComponentPublicInstance } from 'vue';

export function useItemActionEvent(component?: ComponentPublicInstance | null) {
  const shouldDisplayAlcoholInput = ref(false);
  const menuStore = useMenuStore();
  const alcoholInfo = storeToRefs(menuStore).alcohol;
  let updateQueue: Array<() => void> = [];

  function enableAlcohol() {
    menuStore.setAlcoholValue({ allow: true, checked: true });
    updateQueue.forEach((action) => action());
    updateQueue = [];
    shouldDisplayAlcoholInput.value = false;
  }

  function disableAlcohol() {
    menuStore.setAlcoholValue({ allow: false, checked: true });
    updateQueue = [];
    shouldDisplayAlcoholInput.value = false;
  }

  function needAlcoholCheck(_item: ItemDescriptor, action: () => void) {
    if (
      !alcoholInfo.value.checked
      && _item.itemMeta.skuDetails.containsAlcohol
    ) {
      shouldDisplayAlcoholInput.value = true;
      updateQueue.push(action);
      return true;
    }
    return false;
  }

  const showMaximumAnimation = ref(false);

  function selectItem(_item: ItemDescriptor) {
    if (needAlcoholCheck(_item, () => selectItem(_item))) {
      return;
    }

    if (selectable(_item.actions)) {
      _item.actions.select();
    }
    if (quantifiable(_item.actions)) {
      _item.actions.increase();
    }

    if (reachedMaximumQuantity(_item)) {
      showMaximumAnimation.value = true;
    }

    component?.$forceUpdate();
  }

  function increaseItemQuantity(_item: ItemDescriptor): void {
    if (needAlcoholCheck(_item, () => increaseItemQuantity(_item))) {
      return;
    }

    if (quantifiable(_item.actions)) {
      _item.actions.increase();
    }

    if (reachedMaximumQuantity(_item)) {
      showMaximumAnimation.value = true;
    }

    component?.$forceUpdate();
  }

  function decreaseItemQuantity(_item: ItemDescriptor): void {
    if (needAlcoholCheck(_item, () => decreaseItemQuantity(_item))) {
      return;
    }

    if (quantifiable(_item.actions)) {
      if (_item.modifiers) {
        _item.discardSelection();
      } else {
        _item.actions.decrease();
      }
    }

    component?.$forceUpdate();
  }

  return {
    selectItem,
    increaseItemQuantity,
    decreaseItemQuantity,
    enableAlcohol,
    disableAlcohol,
    shouldDisplayAlcoholInput,
    showMaximumAnimation,
  };
}
