import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { orderRoutes } from '@/modules/orders/routes';
import { RouteName } from '@/common/routes/routeName';

import Init from '@/Init.vue';
import WelcomeView from '@/modules/welcome/WelcomeView.vue';
import StandbyBannerView from '@/modules/time/views/StandbyBannerView.vue';
import NotFound from '@/NotFound.vue';
import MaintenanceBanner from '../modules/time/views/MaintenanceBanner.vue';

export const routes: RouteRecordRaw[] = [
  {
    path: '/:integration/:storeId',
    component: Init,
    name: RouteName.STORE,
    children: [
      {
        path: '',
        name: RouteName.WELCOME,
        component: WelcomeView,
      },
      {
        path: 'standby',
        name: RouteName.STAND_BY_BANNER,
        component: StandbyBannerView,
      },
      {
        path: 'maintenance',
        name: RouteName.MAINTENANCE,
        component: MaintenanceBanner,
      },
      {
        path: 'order',
        name: RouteName.ORDERS,
        children: [...orderRoutes],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: RouteName.NOT_FOUND,
    component: NotFound,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
