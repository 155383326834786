/* eslint-disable max-lines-per-function */
import { ButtonSettingsDto, ColorSettingsDto } from '@slabcode/kiosks-core/dtos/settings/brand';
import { ButtonType } from '@/common/types';

const buttonClasses = ref<Record<ButtonType, string>>({
  default: '',
  ghost: '',
  outline: '',
  primary: '',
});

export function useUiComposable() {
  const metadataStore = useMetadataStore();
  const { colors, buttonsSettings } = storeToRefs(metadataStore);

  function setButtonClasses(kioskColors: ColorSettingsDto, settings: ButtonSettingsDto): void {
    const { radius, weight, showIcons } = settings;
    const { ghost, primary } = settings.colors;

    const defaultColor = ['black', '#000'];
    const primaryBg = Object.entries(kioskColors).find(([, v]) => v === primary.background) ?? defaultColor;
    const ghostText = Object.entries(kioskColors).find(([, v]) => v === ghost.text) ?? defaultColor;

    const [primaryColor] = primaryBg;
    const [ghostColor] = ghostText;
    // To detect complete tailwind class -> https://github.com/tailwindlabs/tailwindcss/discussions/13052
    const primarySufix = (primaryColor === 'black') ? primaryColor : `${primaryColor}-500`;
    const isPrimaryTextWhite = primary.text.toLowerCase() === '#fff';
    const primaryTextClass = isPrimaryTextWhite ? '!text-white disabled:!text-white' : '!text-black disabled:!text-black';
    const primaryTextHover = isPrimaryTextWhite ? 'hover:!text-white' : 'hover:!text-black';
    // Set classes
    buttonClasses.value.default = 'btn-outline bg-white shadow-neutral-400/50 border-black text-black';
    buttonClasses.value.ghost = `btn-ghost !shadow-none text-${ghostColor}-500`;
    buttonClasses.value.outline = `btn-outline hover:bg-${primarySufix} hover:border-${primarySufix} border-${primarySufix} text-${primarySufix} ${primaryTextHover}`;
    buttonClasses.value.primary = `border-${primarySufix} bg-${primarySufix} ${primaryTextClass}`;

    const iconClass = showIcons ? 'show-button-icon' : 'hide-button-icon';
    // @ts-ignore
    Object.keys(buttonClasses.value).forEach((key: ButtonType) => {
      buttonClasses.value[key] += ` ${radius} ${weight} ${iconClass}`;
    });
  }

  watch([colors, buttonsSettings], ([newColors, newSettings]) => {
    if (newColors && newSettings) {
      setButtonClasses(newColors, newSettings);
    }
  }, { deep: true });

  // To allow get classes if user reload page
  onBeforeMount(() => {
    const hasInitialValues = !buttonClasses.value.default.length;
    const hasLoadedMetadata = colors.value && buttonsSettings.value;

    if (hasInitialValues && hasLoadedMetadata) {
      setButtonClasses(colors.value, buttonsSettings.value);
    }
  });

  return {
    buttonClasses,
  };
}
