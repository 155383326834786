import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { i18n } from '@/config';
import { router } from '@/router';

// Styles
import 'animate.css';
import './assets/css/style.css';

import App from './App.vue';

// To start mock server
if (import.meta.env.VITE_NODE_ENV === 'development.local') {
  worker.start({ onUnhandledRequest: 'bypass' });
}

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://3f6cf12e798e42582ac01d24f7ae8940@o4507424848084992.ingest.us.sentry.io/4507424857063424',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/kiosk.otter.slabcode.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// i18n
app.use(i18n);
// Pinia
app.use(createPinia());
// Router
app.use(router);
// Mount app
app.mount('#app');
