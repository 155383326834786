<script lang="ts" setup>
import { PropType } from 'vue';
import { FadeColors } from '../enums/fadeColors';
import { FadePosition } from '../types/fadePosition';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  color: {
    type: String as PropType<FadeColors>,
    required: true,
  },
  position: {
    type: String as PropType<FadePosition>,
    required: false,
    default: 'top',
  },
});

const metadataStore = useMetadataStore();

const { colors } = storeToRefs(metadataStore);
const { color } = toRefs(props);

const colorClass = computed(() => {
  if (color.value === FadeColors.WHITE) {
    return '#fff';
  }

  if (color.value === FadeColors.BACKGROUND && colors.value) {
    return colors.value.background;
  }

  return 'transparent';
});

</script>

<template>
  <Transition>
    <div
      v-if="show"
      :style="{
        '--tw-gradient-from': `${colorClass} var(--tw-gradient-from-position)`,
        '--tw-gradient-to': 'rgb(0 0 0 / 0) var(--tw-gradient-to-position)',
        '--tw-gradient-stops': 'var(--tw-gradient-from), var(--tw-gradient-to)',
      }"
      :class="[
        'fade-container',
        position === 'top' ? 'bg-gradient-to-b -top-1' : 'bg-gradient-to-t -bottom-1',
      ]"
    />
  </Transition>
</template>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.fade-container {
  @apply absolute z-10 w-full h-32 to-transparent;
}
</style>
