<script setup lang="ts">
import AlertImage from '@/assets/alert.svg';

const props = defineProps({
  modifiers: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t } = useI18n();
const emit = defineEmits(['cancel', 'confirm']);

const description = computed(() => (props.modifiers ? t('ALCOHOL.MODIFIERS_MESSAGE') : t('ALCOHOL.GALLERY_MESSAGE')));
</script>

<template>
  <ConfirmModal
    :icon="AlertImage"
    :title="t('ALCOHOL.TITLE')"
    :message="description"
    :local-modal="true"
    :success-button-label="t('ALCOHOL.CONFIRM')"
    :deny-button-label="t('ALCOHOL.CANCEL')"
    @on-close="emit('cancel')"
    @on-ok="emit('confirm')"
  />
</template>

<style scoped>

</style>
