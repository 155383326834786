import { HubsterItemModifier } from '@slabcode/hubster-models/hubster/common/item';
import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';

const { t } = i18n.global;

function getModifierDescription(item: HubsterItemModifier) {
  let result = item.name;

  if (!item.modifiers?.length) return result;

  item.modifiers.forEach((m) => {
    result += ` (${m.quantity} ${getModifierDescription(m)})`;
  });

  return result;
}

export function getCartItemDescription(modifiers: HubsterOrderModifier[]) {
  let result = '';

  if (modifiers.length === 0) return result;

  modifiers.forEach((m) => {
    // Add label when the combo was upgrade
    if (m.name?.includes('Upgrade')) {
      result += `${t('ORDER.UPGRADE_LABEL')} + `;
      return;
    }

    result += `${m.quantity}x ${(m.modifiers?.length) ? getModifierDescription(m) : m.name}  + `;
  });

  return result;
}
