import type { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export function useErrorInterceptor(instance: AxiosInstance, apiUrl: string) {
  instance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const metadataStore = useMetadataStore();
      const { integration } = storeToRefs(metadataStore);

      if (apiUrl?.length) {
        return { ...config, baseURL: apiUrl.replace('INTEGRATION', integration.value.toLowerCase()) };
      }

      return config;
    },

    (error) => Promise.reject(error),
  );

  /**
  * Show notify with error message
  */
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,

    async (error) => Promise.reject(error.response?.data),
  );
}
