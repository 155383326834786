<script setup lang="ts">
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const settingsStore = useSettingsStore();
const metadataStore = useMetadataStore();
const { triggerGoBack } = useGTMEventsComposableV2();
const { mainFooterImage, mainHeaderImage } = storeToRefs(metadataStore);

const isNotCategories = computed(() => route.name !== 'OrderCategoriesView');

function goBack() {
  // Trigger GTM event
  triggerGoBack(route.name);

  if (settingsStore.multibrandData) {
    const { backUrl } = settingsStore.multibrandData;
    window.location.href = backUrl;
    return;
  }

  router.back();
}
</script>

<template>
  <section class="component-wrapper full-hd">
    <img
      v-if="mainHeaderImage"
      :src="mainHeaderImage"
      alt="Main Header"
      class="top-0 custom-image"
    >

    <OrderOptions
      :hide-carousel="!isNotCategories"
      :back-button-text="t('BACK')"
      @go-back="goBack()"
    >
      <router-view />
    </OrderOptions>

    <img
      v-if="mainFooterImage"
      :src="mainFooterImage"
      alt="Main Footer"
      class="bottom-0 custom-image"
    >
  </section>
</template>

<style scoped>
.component-wrapper {
  @apply flex flex-col justify-center items-center relative;
}

.custom-image {
  @apply h-40 absolute left-1/2 -translate-x-1/2;
}
</style>
