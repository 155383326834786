import { useI18n } from 'vue-i18n';
import { CouponValidation, CouponValidationResponse } from '../interfaces';

export const useCouponStore = defineStore(
  'coupon',
  () => {
    const metadataStore = useMetadataStore();
    const languageStore = useLanguageStore();
    const cartStore = useCartStoreV2();
    const orderTypeStore = useOrderTypeStore();

    const promoCode = ref('');
    const loading = ref(false);
    const response = ref<CouponValidationResponse | null>(null);
    const err = ref('');
    const couponIds = ref<number[]>([]);
    const discount = ref<number>(0);
    const { t } = useI18n();

    /**
     * Validate Coupon
     * @param promoCode string
     */
    async function validatePromoCode() {
      const validationObj: CouponValidation = {
        integrationName: metadataStore.integration,
        storeId: metadataStore.storeId,
        promoCode: promoCode.value,
        couponIds: couponIds.value,
        couponValues: {
          timestamp: new Date().toLocaleString('sv'),
          language: languageStore.currentLanguage.toUpperCase(),
          orderInfo: {
            itemsQuantity: cartStore.items.length,
            fulfillmentMode: orderTypeStore.fulfillmentMode,
            subtotal: cartStore.subtotal,
          },
        },
      };

      loading.value = true;
      try {
        const res = (await couponRequests.post('/coupons/validate', validationObj)) as CouponValidationResponse;

        response.value = res;
        discount.value = res.appliedCouponInfo.discount;
        couponIds.value = res.redeemedCoupons.map((coupon) => coupon.id);
      } catch (error: any) {
        if (error.message) {
          err.value = error.message;
        } else {
          err.value = t('INTERNAL_ERROR');
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      loading,
      couponIds,
      err,
      discount,
      response,
      promoCode,
      validatePromoCode,
    };
  },
);
