import axios, { AxiosInstance } from 'axios';

export function axiosInstanceBuilder(apiUrl: string) {
  const isLocalEnv = import.meta.env.VITE_NODE_ENV === 'development.local';

  const instance: AxiosInstance = axios.create({
    baseURL: isLocalEnv ? '/' : apiUrl,
    headers: {
      Accept: 'application/json', // https://github.com/axios/axios/issues/4783
      'Content-Type': 'application/json',
    },
  });

  return {
    useErrorInterceptor() {
      useErrorInterceptor(instance, apiUrl);
      return this;
    },
    getInstance: () => instance,
  };
}
