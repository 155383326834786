import { setupLibConfig } from '@slabcode/hubster-modifiers-utils';
import { GetMenuResponseDto } from '../../modules/orders/interfaces/getMenuResponseDto';

const error = ref('');
const loading = ref(false);

export function useMenuController() {
  const menuStore = useMenuStore();

  const getMenu = async (storeId: string, lang: string) => {
    loading.value = true;
    try {
      const response: GetMenuResponseDto = await requests.get(
        `${storeId}/menus`,
        {
          params: {
            timestamp: new Date().toLocaleString('sv'),
            language: lang.toUpperCase(),
          },
        },
      );

      menuStore.storeId = storeId;

      if (response) {
        menuStore.setMenuData(response);
        setupLibConfig({
          categoriesMap: response.menu.categories,
          itemsMap: response.menu.items,
          modifiersMap: response.menu.modifierGroups,
        });
      }
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        error.value = err.message;
      }
    } finally {
      loading.value = false;
    }
  };

  return { getMenu, loading, error };
}
