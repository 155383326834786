<script lang="ts" setup>
import { useDateFormat } from '@vueuse/core';
import { useTimeStore } from '@/common/stores/time';

const timeStore = useTimeStore();
const metadataStore = useMetadataStore();
const settingsStore = useSettingsStore();

const { now } = storeToRefs(timeStore);
const { customImages } = storeToRefs(metadataStore);
useStandbyBannerStore();

const time = useDateFormat(now, 'hh:mm AA');

onMounted(() => {
  if (!metadataStore.kioskSchedules) {
    settingsStore.refreshMetadata();
  }
});
</script>

<template>
  <section class="relative full-hd">
    <div class="clock">
      {{ time }}
    </div>
    <KioskImage image-class="relative full-hd" :src="customImages?.standbyBanner ?? 'https://storage.googleapis.com/kiosks-production/Defaults/DefaultStandbyBanner.png'" />
  </section>
</template>

<style scoped>
.clock {
  @apply absolute top-0 right-0 p-4 text-white text-3xl z-10;
}
</style>
