<script setup lang="ts">
const maintenanceStore = useMaintenanceStore();
</script>

<template>
  <div v-if="maintenanceStore.banner" class="flex items-center justify-center h-screen">
    <KioskImage
      :src="maintenanceStore.banner"
      alt="Maintenance image"
      hide-border
    />
  </div>
</template>
