import { rest } from 'msw';
import { menuData } from '@/mocks/data';

export const menuRequests = (msw: typeof rest) => [
  msw.get(':storeId/menus', async (_, res, ctx) => res(
    ctx.status(200),
    ctx.delay(1000),
    ctx.json(
      menuData,
    ),
  )),

  msw.get(':storeId/voucher', async (req, res, ctx) => {
    const { storeId } = req.params;
    const successResponse = { ...VOUCHER_ITEM };
    const invalidResponse = { message: 'Cupón no válido' };
    const isValidVoucher = !storeId.includes('invalid');

    return res(
      ctx.status(isValidVoucher ? 200 : 400),
      ctx.delay(4000),
      ctx.json(
        isValidVoucher ? successResponse : invalidResponse,
      ),
    );
  }),
];

// export default menuRequests;
