<script setup lang="ts">
defineProps({
  modalClass: {
    type: String,
    required: false,
    default: '',
  },
});

const showContent = ref(false);
const metadataStore = useMetadataStore();
const { modalFooterImage, cardRadiusClass, modalHeaderImage } = storeToRefs(metadataStore);

onMounted(() => {
  showContent.value = true;
});
</script>

<template>
  <Teleport to="#modals">
    <div
      v-if="showContent"
      class="modal-background full-hd"
    >
      <div class="kiosk-modal">
        <div
          :class="[modalClass, cardRadiusClass]"
          class="relative overflow-hidden modal-content"
        >
          <img
            v-if="modalHeaderImage"
            :src="modalHeaderImage"
            alt="Modal Header"
            class="top-0 modal-image"
          >

          <slot />

          <img
            v-if="modalFooterImage"
            :src="modalFooterImage"
            alt="Modal Footer"
            class="bottom-0 modal-image"
          >
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.kiosk-modal {
  @apply flex justify-center items-center h-full;
}

.modal-background {
  @apply absolute z-30 bg-neutral-500 backdrop-blur-sm bg-opacity-25;
}

.modal-content {
  @apply w-1/2 bg-white shadow-xl shadow-neutral-300/50 py-28 px-8 flex flex-col items-center justify-start ;
}

.modal-image {
  @apply h-20 absolute left-1/2 -translate-x-1/2;
}
</style>
