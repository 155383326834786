import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

export function isReadonlyItem(item: ItemDescriptor): boolean {
  return (
    !!item.parent?.isRequired
    && item.parent?.items.length === 1
    && item.parent.dynamicMinimumSelections
      === item.parent.dynamicMaximumSelections
  );
}
