/* eslint-disable no-unused-vars */
export enum GTMEvent {
  VIEW_ITEM_LIST = 'view_item_list',
  SELECT_ITEM = 'select_item',
  UPSELLING = 'UPSELLING',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_PAYMENT_INFO = 'add_payment_info',
  PURCHASE = 'purchase',
  CANCEL_ORDER = 'cancel_order',
  SCALE_UP_ITEM = 'scale_up_item',
  START_ORDER = 'start_order',
  SELECT_ORDER_TYPE = 'select_order_type',
  ADD_TABLE_NUMBER = 'add_table_number',
  SELECT_CATEGORY = 'select_category',
  IDLE_MODAL = 'idle_modal',
  CANCEL_IDLE_MODAL = 'cancel_idle_modal',
  GO_BACK = 'go_back',
  ADD_TO_CART = 'add_to_cart',
  EDIT_VARIATION = 'edit_variation'
}
