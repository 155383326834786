import { defineStore } from 'pinia';
// TODO: Delete store
export const useModalStore = defineStore('modal', {
  state: () => ({
    showModal: false,
  }),

  actions: {
    /**
     * This function display the corresponding modal
     */
    openModal() {
      this.showModal = true;
    },
    /**
     * This function hide the corresponding modal
     */
    closeModal() {
      this.$reset();
    },
  },
});
