export function useCurrencyModal() {
  const isOpenCurrencyModal = ref(false);

  const openCurrencyModal = () => {
    isOpenCurrencyModal.value = true;
  };

  const closeCurrencyModal = () => {
    isOpenCurrencyModal.value = false;
  };

  return {
    isOpenCurrencyModal,
    openCurrencyModal,
    closeCurrencyModal,
  };
}
