<script setup lang="ts">
defineProps({
  time: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close', 'inactive']);

const { t } = i18n.global;
</script>

<template>
  <KioskModal>
    <h2 class="dialog-title">
      {{ t('INACTIVITY.TITLE') }}
    </h2>

    <div class="relative flex items-center justify-center w-full my-10">
      <span class="icon text-9xl text-primary-600 icon-timer" />

      <span class="circle-counter bg-primary-600">
        {{ time }}s
      </span>
    </div>

    <div class="flex justify-center">
      <KioskButton
        color="primary"
        text-size="medium"
        @click="emit('close')"
      >
        {{ t('CONTINUE') }}
      </KioskButton>
    </div>
  </KioskModal>
</template>

<style scoped>
.modal-text {
  @apply text-2xl text-neutral-600 leading-8 tracking-tight text-center;
}

.circle-counter {
  @apply flex items-center justify-center text-5xl font-bold text-white h-[90px] w-[90px] rounded-full shadow-xl absolute mt-4 tracking-wider;
}
</style>
