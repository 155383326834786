import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { selectedItems } from '@/utils/modifierSelectedItems';

export function itemTaxes(item: ItemDescriptor): number {
  const ownTax = (item.itemMeta.price.amount * item.quantity)
    * (item.itemMeta.tax.percentageValue.decimalValue);
  const modifiersTaxes = selectedItems(item.modifiers).reduce(
    (total, _item) => total + itemTaxes(_item),
    0,
  ) * item.quantity;
  return ownTax + modifiersTaxes;
}
