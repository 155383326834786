import { ICartItem } from '@/modules/cart/interfaces/cartState';
import { GTMCartItem } from '../interfaces/gtmCartItems';

export function toGTMCartItem({ item, description, identifier }: ICartItem): GTMCartItem {
  return {
    category: item.category,
    description,
    identifier,
    id: item.itemMeta.id,
    name: item.itemMeta.name,
    total: item.total,
    price: item.itemMeta.price.amount,
    quantity: item.quantity || 1,
  };
}
