/* eslint-disable no-unused-vars */
export enum RouteName {
  WRAPPER = 'OrderWrapper',
  STORE = 'Store',
  STAND_BY_BANNER = 'StandbyBanner',
  MAINTENANCE = 'maintenance',
  NOT_FOUND = 'not-found',
  ORDERS = 'Orders',
  WELCOME = 'WelcomeView',
  ORDER_OPTIONS = 'OrderOptions',
  ORDER_TYPE = 'OrderTypeView',
  ORDER_CATEGORIES = 'OrderCategoriesView',
  PRODUCTS = 'OrderProductsView',
  STEP_CUSTOMIZE = 'StepCustomize',
  DROPDOWN_CUSTOMIZE = 'DropdownCustomize',
  CART = 'CartV2',
  CHECKOUT = 'CheckoutV2',
}
