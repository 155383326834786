import { SalesStatus } from '@slabcode/hubster-models/enums/hubster';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';

/* eslint-disable max-lines */
export const menuData = {
  menus: {
    '5906e764-479c-4528-9186-4c7e5654f97f': {
      name: 'PRB-KFC-ES',
      categoryIds: [
        '2eef7458-d370-4cb1-9702-c0a8305610c7',
        'b1b554cc-9f2c-493a-a4c6-291e277fa90d',
        '110f1eda-0779-49eb-a5da-f477a314647e',
        '98feb716-4340-4eda-bb76-14c13f72cc92',
        'da60092e-a1d4-47d2-9c9d-4c767aeaf388',
        'daaf2bbe-979c-43bd-9a21-c9a5c1471e93',
        '15d651aa-e4a1-486f-b3f6-e039b37d7c34',
        '6af779ad-b687-4b5c-b9ad-ccbb548e380d',
        'ff27c720-bd03-4193-99c4-db44c6866414',
      ],
      fulfillmentModes: [
        'DELIVERY',
        'PICK_UP',
        'DINE_IN',
      ],
      additionalCharges: [],
      id: '5906e764-479c-4528-9186-4c7e5654f97f',
      hoursData: {
        timeZone: 'GMT',
        regularHours: [
          {
            days: [
              'MONDAY',
              'TUESDAY',
              'WEDNESDAY',
              'THURSDAY',
              'FRIDAY',
              'SATURDAY',
              'SUNDAY',
            ],
            timeRanges: [
              {
                start: '05:00',
                end: '23:30',
              },
            ],
          },
        ],
        specialHours: [],
      },
    },
  },
  categories: {
    '2eef7458-d370-4cb1-9702-c0a8305610c7': {
      name: 'BOXES DE KE TIRAS',
      description: 'https://blob-storage.com/photos/menu-photos/e69eb9a0-4629-4513-888c-7b0471e121a7.png',
      itemIds: [
        '61e254b6-7a85-4207-a2dd-dacee4af0116',
        '1bca9927-c475-4e52-85e3-446bb00158c7',
        '775258aa-f2e1-4063-ab2c-060e6bfb3f7a',
      ],
      id: '2eef7458-d370-4cb1-9702-c0a8305610c7',
    },
    'b1b554cc-9f2c-493a-a4c6-291e277fa90d': {
      name: 'BOXES DE PIEZAS DE POLLO',
      description: 'https://blob-storage.com/photos/menu-photos/840e5d34-1e1e-43b9-bdcf-58e88289148a.png',
      itemIds: [
        'e940c1a9-13b5-454d-808d-6c62e7cc22f8',
        '097859c6-f871-4901-bd2f-f67e9e22bfc6',
        '8ff1e804-bf9f-4fe1-8102-3783694b6f11',
      ],
      id: 'b1b554cc-9f2c-493a-a4c6-291e277fa90d',
    },
    '110f1eda-0779-49eb-a5da-f477a314647e': {
      name: 'OTROS ANTOJOS',
      description: 'https://blob-storage.com/photos/menu-photos/fa6f3b83-d092-4142-94c8-0996d4691f09.png',
      itemIds: [
        '4c4b2b17-bf79-4682-b241-3e1d4d45ee12',
        'd18269d0-b3e0-4b67-9911-2a05169cc74d',
        '629c2577-2293-4ad5-8e20-526e70aec947',
        '6b32a053-ec14-4eb0-a802-b29a5cbc7beb',
        'c6217b9d-e332-49ce-91f5-0acc6c8c0f9e',
        '07e50f9e-b603-4918-9643-9b68386768e2',
        '13077eed-92d1-4c30-aadb-8f8c1c694f9d',
        '6ef91483-48e5-4766-95e3-d90b1f9120cf',
        'a3057010-703d-4cd7-a651-5a590b8e73c4',
        '9e0645ba-54df-464c-b99e-6c5d1233c42c',
        'fa618edd-8715-4af8-a543-968e27b269f3',
        'e9c99a99-373f-4cec-a9d2-52378767c23f',
        '89b6ad90-3dc3-46d0-ac6e-80e6e4b3d416',
        '74636573-1c01-4a72-8d72-60e76a7552f5',
        '8b95f747-bd25-4486-ba5f-a3e9183dbd8d',
        'e405ac95-81fa-4103-9a90-4c95f6ef6195',
        'e40c7caa-28b9-4dff-9b97-8f635c869e3e',
        '938ce2fc-6f3f-426d-89bf-08f42c8df434',
        '77e418b7-95bb-4fa8-83be-44163fdb8f68',
        'f830631f-a1c6-4e1f-9a3f-6c99ff157023',
        '2d68e68e-56fc-4381-a766-30c75e378394',
        '4e041271-6717-4f63-97be-81d81272a1e7',
        '5f499c46-afb8-4e6e-be8d-5723e105e14e',
        '2f38e51f-ba80-4ebb-84b9-de86b1e970de',
        'c2325d2d-745f-400b-9ce1-82c8a013023a',
        'b365bd91-d16e-4a2f-9917-73c63cea19f8',
        '5ba7ddaf-2b00-439f-a83f-cae324cb6c5f',
        'c2f1eec9-2e79-4a42-b98d-cbd2aef51d34',
        '9296c13d-3242-4b8f-a7ae-6af1c92e2da6',
        '193b514e-a549-4f53-ad62-fc4602a1220d',
        'fb03d98b-51d0-429e-bd29-9474146661ea',
        '0a7834f6-e3d8-4a8e-a98c-4e43b12c58d1',
        '0858f637-8ae6-4a5e-92db-28d8336a0c8c',
        '9c294930-84f1-4362-869e-55314b46b9b6',
        '23daf933-5cfc-48f4-9456-65a5ce517271',
        '9f6bf439-b288-4ec4-8569-bba15ee81b11',
      ],
      id: '110f1eda-0779-49eb-a5da-f477a314647e',
    },
    '98feb716-4340-4eda-bb76-14c13f72cc92': {
      name: 'HAMBURGUESAS',
      description: 'https://blob-storage.com/photos/menu-photos/178c3e8e-e926-4a67-8afe-504d7ed6567d.png',
      itemIds: [
        'fd9e391c-c149-48a0-be5d-bec58b739296',
        'a3057010-703d-4cd7-a651-5a590b8e73c4',
        'e6270642-bad5-49ac-bc3f-206b2ff14fee',
        '944a837a-97c4-4717-9ad5-3998e4e373ca',
      ],
      id: '98feb716-4340-4eda-bb76-14c13f72cc92',
    },
    'da60092e-a1d4-47d2-9c9d-4c767aeaf388': {
      name: 'KENTUCKY CHICKEN SANDWICH',
      description: 'https://blob-storage.com/photos/menu-photos/4c9f2a49-7b1b-4bcb-aba4-a2dea5d15bfb.png',
      itemIds: [
        'acb67920-f3bd-4536-a852-14ecb861c4d2',
        'b7291bd6-3ac4-4740-a27b-454f9fc98227',
        '60c934b6-03b9-4752-b44c-ce5da4f6e2a9',
        '9cc21c94-24cf-4e64-af10-8c2c79c4a714',
        '4069f454-9b06-42c4-b888-9aa55796f32c',
        '5a76a267-f8b4-483f-8de6-d85fc67a2f1c',
        'e05a7788-6ff4-4669-955b-d356d5d91b63',
        'df9eba1e-4c92-477a-a504-d71a97d4e7f9',
        'a3570011-42aa-4c75-a2bb-6aef324d5340',
        'fdba5123-ec71-4900-a904-ed46cc8b296f',
        '499c5507-c1cb-48b9-9cbd-b3d1c87b1f19',
        '31ab6c85-cbc3-4c1d-835f-12eaa8c2f903',
      ],
      id: 'da60092e-a1d4-47d2-9c9d-4c767aeaf388',
    },
    'daaf2bbe-979c-43bd-9a21-c9a5c1471e93': {
      name: 'PAQUETES FAMILIARES',
      description: 'https://blob-storage.com/photos/menu-photos/8cd2625d-35dd-40f5-ac97-1a9ae4f672d3.png',
      itemIds: [
        '1559a820-35d3-4cf4-8e5f-5373ecd3a934',
        '705193ce-fb40-41b4-a92f-3750329c01f6',
        '109739ce-bcf0-423d-83a4-bc71ef3f4001',
        'c8074676-8ecf-4099-a645-213a75c3f05f',
        '50000898-522e-478a-8f89-31ebdaf120d6',
        'ebf3d8f8-9bd8-42a4-8ecb-25ed228bf826',
        'ff779013-af20-47fd-953a-dee83aadcc7e',
        'ece20580-d9b3-46c1-b3ec-8e8ad47dda81',
      ],
      id: 'daaf2bbe-979c-43bd-9a21-c9a5c1471e93',
    },
    '15d651aa-e4a1-486f-b3f6-e039b37d7c34': {
      name: 'PARA NIÑOS',
      description: 'https://blob-storage.com/photos/menu-photos/c97648a9-e0b2-4dfb-a38d-ed0df6350304.png',
      itemIds: [
        'ab62e683-58c7-4bd4-9f2a-c6781ebc61e3',
        '24b8863f-6aba-4002-b29f-5387ceecf5c3',
        'bd3774f6-83ac-40f9-9d69-3fa03827a230',
        '188bacba-e991-40c5-af98-b659ff956f4a',
      ],
      id: '15d651aa-e4a1-486f-b3f6-e039b37d7c34',
    },
    '6af779ad-b687-4b5c-b9ad-ccbb548e380d': {
      name: 'POSTRES',
      description: 'https://blob-storage.com/photos/menu-photos/843a3675-3d95-4ef5-bc3a-3022b164a282.png',
      itemIds: [
        '13939570-0bac-4d1a-9223-21f24a93a0ab',
        '850d1a36-3ce6-442a-8a55-ef08d95307ca',
        'b21e5b4a-2242-463c-8067-fc0f342ab462',
        '221a8ebf-adf0-4338-930f-a00a75b2bc04',
        '4c63041f-985b-48f6-9959-1f4cd58fbde0',
        '7ca0153d-d631-4446-93de-b7552ed2e1c6',
        '0a6224e4-1381-4664-8b3c-4e82ff2aac23',
        '3eb4cf7d-c4b9-434f-a989-f5a19ffee9a3',
        '7e8affbf-0f6d-4dc1-a12f-7b99aced6ce0',
        'f0707040-bb0a-472e-8732-3fd0f96401ac',
        '8b58d8c4-98a8-4f25-be51-75b31803d4f0',
        '044fd586-7f5a-419c-bbb6-447243ef089c',
        '61ab46f8-2875-4497-9dc8-86aa1796f432',
        'ebb52a68-4e41-45b0-916f-e9563a13c9c1',
        '643c14a0-b308-41f8-8a89-fc8a73e6bc8c',
        '3c784368-6cb8-4d41-adc8-c6169e009b1b',
        '3eeb3ccb-8023-42ce-ae77-dc3c8bd7cb9c',
        '193b514e-a549-4f53-ad62-fc4602a1220d',
      ],
      id: '6af779ad-b687-4b5c-b9ad-ccbb548e380d',
    },
    'ff27c720-bd03-4193-99c4-db44c6866414': {
      name: 'PAQUETES VARIEDAD',
      description: 'https://blob-storage.com/photos/menu-photos/6bd7b8f4-a4df-4f6c-a92a-bd37f656313b.png',
      itemIds: [
        '928ee821-c006-4ba5-9178-0ab88d9c9264',
        '1f94b753-f846-41cf-a48f-066e8f6cef10',
      ],
      id: 'ff27c720-bd03-4193-99c4-db44c6866414',
    },
  },
  items: {
    '2d68e68e-56fc-4381-a766-30c75e378394': {
      name: 'Papas Chicas',
      description: 'https://photos.tryotter.com/menu-photos/90af4197-204c-4e2c-a879-b49c87300896.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ee01b915-cdae-477e-abd3-be53d8401018',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '2d68e68e-56fc-4381-a766-30c75e378394',
      photoIds: [],
      priceOverrides: [],
    },
    '13939570-0bac-4d1a-9223-21f24a93a0ab': {
      name: 'Pay de Manzana',
      description: 'https://photos.tryotter.com/menu-photos/c044253f-b26e-4ef3-ae0f-3010dff84d05.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 25,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ab31513b-b561-4fa6-a8be-9f0123b16c9f',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '13939570-0bac-4d1a-9223-21f24a93a0ab',
      photoIds: [],
      priceOverrides: [],
    },
    '61e254b6-7a85-4207-a2dd-dacee4af0116': {
      name: 'Big Box Ke Tiras 3 Piezas',
      description: 'https://photos.tryotter.com/menu-photos/dca789ad-9675-4001-9d62-da61015e4527.png',
      modifierGroupIds: [
        '27ea4584-58fc-4b49-a7b8-1eeb85eb8ce0',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 139,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:cf5967a2-faef-4bfc-a994-6391eabee6e0',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '61e254b6-7a85-4207-a2dd-dacee4af0116',
      photoIds: [],
      priceOverrides: [],
    },
    '1bca9927-c475-4e52-85e3-446bb00158c7': {
      name: 'Box  Ke Tiras 2 Piezas',
      description: 'https://photos.tryotter.com/menu-photos/76ec982f-4ba1-4891-8d5d-33796a8b75a3.png',
      modifierGroupIds: [
        '1ea23726-4892-4f4c-9766-7116ae38b255',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 109,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:c79f094a-b6e3-46ba-9979-4c70735bbcc9',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1bca9927-c475-4e52-85e3-446bb00158c7',
      photoIds: [],
      priceOverrides: [],
    },
    '775258aa-f2e1-4063-ab2c-060e6bfb3f7a': {
      name: 'Mega Box Ke Tiras 4 Piezas',
      description: 'https://photos.tryotter.com/menu-photos/c8aa7d9c-8cfd-413a-a681-4177c74d6c7f.png',
      modifierGroupIds: [
        '26f4fa1f-9212-4c04-812e-32d041b749e9',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 149,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:495e597a-760c-402b-b9c0-df0cc24e3656',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '775258aa-f2e1-4063-ab2c-060e6bfb3f7a',
      photoIds: [],
      priceOverrides: [],
    },
    'e940c1a9-13b5-454d-808d-6c62e7cc22f8': {
      name: 'Box Pollo 2 piezas',
      description: 'https://photos.tryotter.com/menu-photos/d0467614-66b7-4653-88c1-d3b7f2f57a7e.png',
      modifierGroupIds: [
        '55be13c9-a9c6-4c65-b2f4-673abcedfe68',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 109,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:9507fd3f-5842-487f-aacb-8409cf8de641',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e940c1a9-13b5-454d-808d-6c62e7cc22f8',
      photoIds: [],
      priceOverrides: [],
    },
    '097859c6-f871-4901-bd2f-f67e9e22bfc6': {
      name: 'Big Box Pollo 3 Piezas',
      description: 'https://photos.tryotter.com/menu-photos/2fc82a60-ec5d-450d-94e1-a601dbfcca68.png',
      modifierGroupIds: [
        '01e23ddf-c1f0-4454-883d-1d1ef1259a9e',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 139,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:1d5cd566-e0e3-4ed9-94ab-eb59b9a9f6b7',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '097859c6-f871-4901-bd2f-f67e9e22bfc6',
      photoIds: [],
      priceOverrides: [],
    },
    '8ff1e804-bf9f-4fe1-8102-3783694b6f11': {
      name: 'Mega Box Pollo 4 Piezas',
      description: 'https://photos.tryotter.com/menu-photos/14af39e9-ad2a-4286-a630-3e908ba649a5.png',
      modifierGroupIds: [
        'eef8c173-df1a-431f-ab8b-67af30025604',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 149,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ce92d502-5424-493e-857a-2d7fd538c708',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '8ff1e804-bf9f-4fe1-8102-3783694b6f11',
      photoIds: [],
      priceOverrides: [],
    },
    '4c4b2b17-bf79-4682-b241-3e1d4d45ee12': {
      name: '1 Pieza',
      description: 'https://photos.tryotter.com/menu-photos/050cb15b-6ae6-4fe0-be13-5d88d09417eb.jpeg',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 34,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:bdd830bb-05b7-4949-bffe-480f8a560508',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '4c4b2b17-bf79-4682-b241-3e1d4d45ee12',
      photoIds: [],
      priceOverrides: [],
    },
    'd18269d0-b3e0-4b67-9911-2a05169cc74d': {
      name: '1 Ke tira',
      description: 'https://photos.tryotter.com/menu-photos/207e7010-319c-4e8c-ab2d-8c0e342f2285.jpeg',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 34,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:36033914-bf9a-4882-9182-f99a17d4015c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd18269d0-b3e0-4b67-9911-2a05169cc74d',
      photoIds: [],
      priceOverrides: [],
    },
    '629c2577-2293-4ad5-8e20-526e70aec947': {
      name: 'Chick N Share Pollo',
      description: 'https://photos.tryotter.com/menu-photos/f23d4e04-3206-44bb-809c-595a61b06e2c.jpeg',
      modifierGroupIds: [
        'ec2914e2-0413-4480-b0ed-6f091921dc7e',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 179,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:b3d35e8a-fb94-4f0c-8449-289f13cdcfb0',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '629c2577-2293-4ad5-8e20-526e70aec947',
      photoIds: [],
      priceOverrides: [],
    },
    '6b32a053-ec14-4eb0-a802-b29a5cbc7beb': {
      name: 'Chick N Share Ke Tiras ',
      description: 'https://photos.tryotter.com/menu-photos/9439c686-5cc2-44d9-b1b6-de33203441aa.jpeg',
      modifierGroupIds: [
        '9b815fca-a541-423a-89ed-6f4cb35ed9ea',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 179,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:6f46e2d0-b059-4604-821a-dd86c47a3a40',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '6b32a053-ec14-4eb0-a802-b29a5cbc7beb',
      photoIds: [],
      priceOverrides: [],
    },
    'c6217b9d-e332-49ce-91f5-0acc6c8c0f9e': {
      name: 'Chick N Share Pollo y Popcorn',
      description: 'https://photos.tryotter.com/menu-photos/d48bb9f1-552c-44cc-ac57-5ee9d640bdad.jpeg',
      modifierGroupIds: [
        '7cced3a6-4dc6-4356-ab95-97cd81fe03af',
        'b2085f22-b66e-4ad5-872c-a25e59e01c64',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 179,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:b95002a7-1004-43df-b745-8556ff874076',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c6217b9d-e332-49ce-91f5-0acc6c8c0f9e',
      photoIds: [],
      priceOverrides: [],
    },
    '07e50f9e-b603-4918-9643-9b68386768e2': {
      name: 'Chick N Share Mix ',
      description: 'https://photos.tryotter.com/menu-photos/c9db74dd-1126-41cd-a873-f73fb2581b68.jpeg',
      modifierGroupIds: [
        'b29280f2-95aa-4f7a-a844-007fa51eb2bd',
        '7018f910-7ee7-4a50-8589-a5dd1f11fed8',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 179,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2785d76a-1c1f-43c1-8b04-3f06e6abbfb2',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '07e50f9e-b603-4918-9643-9b68386768e2',
      photoIds: [],
      priceOverrides: [],
    },
    '5f499c46-afb8-4e6e-be8d-5723e105e14e': {
      name: 'Papas Familiares',
      description: 'https://photos.tryotter.com/menu-photos/804d2e5b-2c1b-4fbe-acea-5f549fb3eac2.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 69,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7ed5cbda-20ee-4103-871b-5b3f897b0484',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '5f499c46-afb8-4e6e-be8d-5723e105e14e',
      photoIds: [],
      priceOverrides: [],
    },
    '13077eed-92d1-4c30-aadb-8f8c1c694f9d': {
      name: '8 piezas solas',
      description: 'https://photos.tryotter.com/menu-photos/69b1de64-c831-41a4-b3d4-784395c11344.jpeg',
      modifierGroupIds: [
        '1c08b972-1111-469c-b034-fe0c8e2c0e56',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 209,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:54a91bfd-11fa-4a33-8b38-154d905b8474',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '13077eed-92d1-4c30-aadb-8f8c1c694f9d',
      photoIds: [],
      priceOverrides: [],
    },
    '6ef91483-48e5-4766-95e3-d90b1f9120cf': {
      name: 'Hamburguesa Kruncher',
      description: 'https://photos.tryotter.com/menu-photos/fcc23bd3-5ee0-401b-bfaa-9b22e641d2b6.png',
      modifierGroupIds: [
        '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 64,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:c1326784-6a95-410c-993b-274f00089ac7',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '6ef91483-48e5-4766-95e3-d90b1f9120cf',
      photoIds: [],
      priceOverrides: [],
    },
    'fb03d98b-51d0-429e-bd29-9474146661ea': {
      name: 'Hot Cheesy Fries Medianas',
      description: 'https://photos.tryotter.com/menu-photos/b54f86d2-ee56-4468-a440-662a35c40791.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 15,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:6afa78c7-985d-48c9-b456-eb9133f539c2',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'fb03d98b-51d0-429e-bd29-9474146661ea',
      photoIds: [],
      priceOverrides: [],
    },
    'a3057010-703d-4cd7-a651-5a590b8e73c4': {
      name: 'Box Ke Tiras Burger',
      description: 'https://photos.tryotter.com/menu-photos/0c6c106d-358e-47ef-9788-c4708b3d53e1.png',
      modifierGroupIds: [
        'bc5a4c5e-1f93-41ec-b9e1-6ecb04e67f88',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        '200ac114-6576-44bb-9d44-6757ae437884',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 125,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f6d6b94c-503c-400e-9dfa-5219b150f682',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'a3057010-703d-4cd7-a651-5a590b8e73c4',
      photoIds: [],
      priceOverrides: [],
    },
    '9e0645ba-54df-464c-b99e-6c5d1233c42c': {
      name: 'Hamburguesa Big Krunch',
      description: 'https://photos.tryotter.com/menu-photos/0cc5b543-be53-4cdd-aa5b-01972ac5f34a.png',
      modifierGroupIds: [
        '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 95,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:30493cd8-220a-49a5-b34e-ceb5819d909e',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9e0645ba-54df-464c-b99e-6c5d1233c42c',
      photoIds: [],
      priceOverrides: [],
    },
    'fa618edd-8715-4af8-a543-968e27b269f3': {
      name: 'Kentucky Chicken Sandwich Spicy Mayo',
      description: 'https://photos.tryotter.com/menu-photos/55b4b9bf-324a-4f5f-a9ce-3e3c2bb4430f.png',
      modifierGroupIds: [
        '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 99,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:69adfb15-e76e-4b93-ad83-14b65b72d8b8',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'fa618edd-8715-4af8-a543-968e27b269f3',
      photoIds: [],
      priceOverrides: [],
    },
    'e9c99a99-373f-4cec-a9d2-52378767c23f': {
      name: 'Kentucky Chicken Sandwich',
      description: 'https://photos.tryotter.com/menu-photos/c28c0766-ac7d-4993-bfd9-b56a5b2d3150.png',
      modifierGroupIds: [
        '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 99,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:1df1b5fc-bb2e-4702-b5a8-1ca5cc895602',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e9c99a99-373f-4cec-a9d2-52378767c23f',
      photoIds: [],
      priceOverrides: [],
    },
    '89b6ad90-3dc3-46d0-ac6e-80e6e4b3d416': {
      name: 'Kentucky Chicken Sandwich BBQ Bacon',
      description: 'https://photos.tryotter.com/menu-photos/14ee1fb3-7c78-431d-b4e6-515b7cda7099.png',
      modifierGroupIds: [
        '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 145,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:52ebfe0e-933c-4496-8d28-113a21b02b28',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '89b6ad90-3dc3-46d0-ac6e-80e6e4b3d416',
      photoIds: [],
      priceOverrides: [],
    },
    '74636573-1c01-4a72-8d72-60e76a7552f5': {
      name: 'Kentucky Chicken Sandwich Chipotle Bacon',
      description: 'https://photos.tryotter.com/menu-photos/6c0f2e9b-d540-473f-a854-0fcef0cbdc68.png',
      modifierGroupIds: [
        '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 145,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:e75d743c-f1da-4c8e-b6b3-a69b1a0a640c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '74636573-1c01-4a72-8d72-60e76a7552f5',
      photoIds: [],
      priceOverrides: [],
    },
    '8b95f747-bd25-4486-ba5f-a3e9183dbd8d': {
      name: 'Paquete Popcorn Gde',
      description: 'https://photos.tryotter.com/menu-photos/ece04b7a-abf7-42be-aede-56e6f609e7f6.png',
      modifierGroupIds: [
        '9474e192-39e1-42c3-a8cc-64549048bcfc',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 115,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:08ce2945-90da-4767-b524-bee3fc85ed72',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '8b95f747-bd25-4486-ba5f-a3e9183dbd8d',
      photoIds: [],
      priceOverrides: [],
    },
    'e405ac95-81fa-4103-9a90-4c95f6ef6195': {
      name: 'Refresco Mediano',
      description: 'https://photos.tryotter.com/menu-photos/69ce8087-a199-4c4b-8a5e-03f8c41c729e.png',
      modifierGroupIds: [
        '292e340f-04b5-44db-b14d-d64c5ec279d9',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 28,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:bdce62f1-4d30-4aa5-b9c4-037ebe083ea5',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e405ac95-81fa-4103-9a90-4c95f6ef6195',
      photoIds: [],
      priceOverrides: [],
    },
    'e40c7caa-28b9-4dff-9b97-8f635c869e3e': {
      name: 'Refresco Grande',
      description: 'https://photos.tryotter.com/menu-photos/8cbc21d4-4120-4b11-8820-14ce4fd2d3dc.png',
      modifierGroupIds: [
        'a6729d64-bf10-40fb-9294-d5d910c1550b',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 31,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:0a00ec4d-ab4c-4b2c-b420-11a09c835baf',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e40c7caa-28b9-4dff-9b97-8f635c869e3e',
      photoIds: [],
      priceOverrides: [],
    },
    '938ce2fc-6f3f-426d-89bf-08f42c8df434': {
      name: 'Agua',
      description: 'https://photos.tryotter.com/menu-photos/49aef611-34c3-4ac5-99b7-331aae3f769b.jpeg',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 16,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:9c1ce3a8-b10c-4cc4-b6f9-3241cc442b52',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '938ce2fc-6f3f-426d-89bf-08f42c8df434',
      photoIds: [],
      priceOverrides: [],
    },
    '77e418b7-95bb-4fa8-83be-44163fdb8f68': {
      name: 'Jugo de Manzana 237ml',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 26,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:8a5e6dcd-db84-4f18-963b-67df934c7e1d',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '77e418b7-95bb-4fa8-83be-44163fdb8f68',
      photoIds: [],
      priceOverrides: [],
    },
    'f830631f-a1c6-4e1f-9a3f-6c99ff157023': {
      name: 'Papas Medianas',
      description: 'https://photos.tryotter.com/menu-photos/2e1297a8-8ce2-4e45-ac4f-661c6c2822c3.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 45,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:6be468ed-0654-4edc-9d85-3d9ad15bd38a',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'f830631f-a1c6-4e1f-9a3f-6c99ff157023',
      photoIds: [],
      priceOverrides: [],
    },
    '4e041271-6717-4f63-97be-81d81272a1e7': {
      name: 'Papas Grandes',
      description: 'https://photos.tryotter.com/menu-photos/804d2e5b-2c1b-4fbe-acea-5f549fb3eac2.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 59,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:9646cef8-66a9-44e7-bcb4-3a32d6927690',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '4e041271-6717-4f63-97be-81d81272a1e7',
      photoIds: [],
      priceOverrides: [],
    },
    '2f38e51f-ba80-4ebb-84b9-de86b1e970de': {
      name: 'Bisquet',
      description: 'https://photos.tryotter.com/menu-photos/1a1c62d0-f227-4163-a114-8fc3c9110ab1.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 20,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:268b44aa-1aa8-4297-8e15-4bddb02458a3',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '2f38e51f-ba80-4ebb-84b9-de86b1e970de',
      photoIds: [],
      priceOverrides: [],
    },
    'c2325d2d-745f-400b-9ce1-82c8a013023a': {
      name: 'Puré Individual',
      description: 'https://photos.tryotter.com/menu-photos/91cd1858-62df-4e60-a313-f0c596d0c68c.jpeg',
      modifierGroupIds: [
        '61ecc84b-b46c-4d76-9203-5dc7061dbeae',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 39,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:a3936586-975f-4b2b-b343-682d79034ab0',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c2325d2d-745f-400b-9ce1-82c8a013023a',
      photoIds: [],
      priceOverrides: [],
    },
    'b365bd91-d16e-4a2f-9917-73c63cea19f8': {
      name: 'Puré Grande',
      description: 'https://photos.tryotter.com/menu-photos/67cb8534-2231-4d68-9db6-0b03e00c264e.jpeg',
      modifierGroupIds: [
        '61ecc84b-b46c-4d76-9203-5dc7061dbeae',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 45,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:17e1f797-f588-4e8a-a588-426170dc7c2b',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'b365bd91-d16e-4a2f-9917-73c63cea19f8',
      photoIds: [],
      priceOverrides: [],
    },
    '5ba7ddaf-2b00-439f-a83f-cae324cb6c5f': {
      name: 'Puré Familiar',
      description: 'https://photos.tryotter.com/menu-photos/67cb8534-2231-4d68-9db6-0b03e00c264e.jpeg',
      modifierGroupIds: [
        '61ecc84b-b46c-4d76-9203-5dc7061dbeae',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 59,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f3ab635c-f42b-46e7-8ab1-a5d2deb0f4b3',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '5ba7ddaf-2b00-439f-a83f-cae324cb6c5f',
      photoIds: [],
      priceOverrides: [],
    },
    'c2f1eec9-2e79-4a42-b98d-cbd2aef51d34': {
      name: 'Ensalada Individual',
      description: 'https://photos.tryotter.com/menu-photos/769e3bbc-3e77-4a5b-9cdf-c3903c85171f.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 39,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:80e3db21-f32a-4b6c-8083-6d46311dc9d4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c2f1eec9-2e79-4a42-b98d-cbd2aef51d34',
      photoIds: [],
      priceOverrides: [],
    },
    '9296c13d-3242-4b8f-a7ae-6af1c92e2da6': {
      name: 'Ensalada de Col Familiar',
      description: 'https://photos.tryotter.com/menu-photos/912c3342-49d4-4f7d-8845-9ad65648d20f.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 59,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7a9b6521-d2b6-4113-becb-b8682d3f6e71',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9296c13d-3242-4b8f-a7ae-6af1c92e2da6',
      photoIds: [],
      priceOverrides: [],
    },
    '193b514e-a549-4f53-ad62-fc4602a1220d': {
      name: ' Ensalada de Col Grande',
      description: 'https://photos.tryotter.com/menu-photos/368778f5-f6cb-40ae-ae68-de1320c4ac05.jpeg',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 45,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:61f06ce4-697d-44e5-ace4-4d472adae122',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '193b514e-a549-4f53-ad62-fc4602a1220d',
      photoIds: [],
      priceOverrides: [],
    },
    '0a7834f6-e3d8-4a8e-a98c-4e43b12c58d1': {
      name: ' Hot Cheesy Fries Familiares',
      description: 'https://photos.tryotter.com/menu-photos/43cf5c74-614c-41eb-8d6f-0091e6ad6a5b.jpeg',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 105,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:13be916a-b4e9-44da-b5e5-d726f70da355',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '0a7834f6-e3d8-4a8e-a98c-4e43b12c58d1',
      photoIds: [],
      priceOverrides: [],
    },
    '0858f637-8ae6-4a5e-92db-28d8336a0c8c': {
      name: 'Gravy Individual',
      description: 'https://photos.tryotter.com/menu-photos/19ae76bd-5bde-432b-bb82-a7da18225fc9.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 19,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:93c7e2aa-e25b-4685-b955-fbc3d5b58490',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '0858f637-8ae6-4a5e-92db-28d8336a0c8c',
      photoIds: [],
      priceOverrides: [],
    },
    '9c294930-84f1-4362-869e-55314b46b9b6': {
      name: 'Gravy Familiar',
      description: 'https://photos.tryotter.com/menu-photos/dfdc5c34-7b65-404f-b7eb-d94b217dc565.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 29,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:076774f0-4621-4a71-984b-55d06a80ab8d',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9c294930-84f1-4362-869e-55314b46b9b6',
      photoIds: [],
      priceOverrides: [],
    },
    '23daf933-5cfc-48f4-9456-65a5ce517271': {
      name: 'Mac N Cheese Individual',
      description: 'https://photos.tryotter.com/menu-photos/8130ddee-f5cd-497e-a98e-b8be16d250c2.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 49,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:04fc4a47-380e-4ddd-98ff-bc1d782a91d1',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '23daf933-5cfc-48f4-9456-65a5ce517271',
      photoIds: [],
      priceOverrides: [],
    },
    '9f6bf439-b288-4ec4-8569-bba15ee81b11': {
      name: 'Mac N Cheese Familiar',
      description: 'https://photos.tryotter.com/menu-photos/fdf081f1-84eb-4b93-8b1f-e1305f3aedf5.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 89,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:5534dd89-e8db-4d58-a088-09507fe9ad2c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9f6bf439-b288-4ec4-8569-bba15ee81b11',
      photoIds: [],
      priceOverrides: [],
    },
    'fd9e391c-c149-48a0-be5d-bec58b739296': {
      name: 'Big Box Big Krunch',
      description: 'https://photos.tryotter.com/menu-photos/e0c7c9c6-da56-4d08-b35d-f0e7b41566fa.png',
      modifierGroupIds: [
        '3fa76239-c55f-4a33-8d9b-5f933f2f2572',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 145,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:a5c8e935-3383-4683-ae6d-8fc9d59aa683',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'fd9e391c-c149-48a0-be5d-bec58b739296',
      photoIds: [],
      priceOverrides: [],
    },
    'e6270642-bad5-49ac-bc3f-206b2ff14fee': {
      name: 'MegaBox Big Krunch + KeTira',
      description: 'https://photos.tryotter.com/menu-photos/0a78c4bc-a6d0-47e4-bb0c-eff364bad67a.png',
      modifierGroupIds: [
        '3fa76239-c55f-4a33-8d9b-5f933f2f2572',
        '7018f910-7ee7-4a50-8589-a5dd1f11fed8',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 155,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:4b649c50-b637-4d2b-a35b-051810682b37',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e6270642-bad5-49ac-bc3f-206b2ff14fee',
      photoIds: [],
      priceOverrides: [],
    },
    '944a837a-97c4-4717-9ad5-3998e4e373ca': {
      name: 'MegaBox Big Krunch + Pollo',
      description: 'https://photos.tryotter.com/menu-photos/1da03f65-b7d0-4cfe-9ed3-84ae0c661c59.png',
      modifierGroupIds: [
        '3fa76239-c55f-4a33-8d9b-5f933f2f2572',
        'b750587e-038a-49e1-b170-41ef2f4a0710',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        '200ac114-6576-44bb-9d44-6757ae437884',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 155,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:80c9dc6f-fb8c-40cc-818c-c0f72aee912e',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '944a837a-97c4-4717-9ad5-3998e4e373ca',
      photoIds: [],
      priceOverrides: [],
    },
    'acb67920-f3bd-4536-a852-14ecb861c4d2': {
      name: 'Combo Kentucky Ch Sand Clásico',
      description: 'https://photos.tryotter.com/menu-photos/db596ecf-59d3-46f8-bd09-96c18f1f270f.png',
      modifierGroupIds: [
        '612d3748-02f0-4a4f-8cbb-d90f3012301b',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 149,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:1cf02fe2-38b8-4b5d-b1ae-9c3b8344df73',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'acb67920-f3bd-4536-a852-14ecb861c4d2',
      photoIds: [],
      priceOverrides: [],
    },
    'b7291bd6-3ac4-4740-a27b-454f9fc98227': {
      name: 'Combo Sandwich SpicyMayo',
      description: 'https://photos.tryotter.com/menu-photos/05457f2a-2fd9-44df-89c7-d0e5a3e8ebfa.png',
      modifierGroupIds: [
        '552d3a4b-b593-4204-a605-4ef17d49fafa',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 149,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:5e7d4c2d-43fa-49ac-96dc-1ca01960d673',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'b7291bd6-3ac4-4740-a27b-454f9fc98227',
      photoIds: [],
      priceOverrides: [],
    },
    '60c934b6-03b9-4752-b44c-ce5da4f6e2a9': {
      name: 'Combo BBQ Bacon Chicken-Sandwich',
      description: 'https://photos.tryotter.com/menu-photos/7dd7249c-871d-4828-998e-97bceb0dfc9f.png',
      modifierGroupIds: [
        '2ad49e46-4a52-42a5-9ad3-1c369b23a295',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 199,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:6f8b7209-96ba-46b3-8d69-470cc342c8dc',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '60c934b6-03b9-4752-b44c-ce5da4f6e2a9',
      photoIds: [],
      priceOverrides: [],
    },
    '9cc21c94-24cf-4e64-af10-8c2c79c4a714': {
      name: 'Combo Chipotle Bacon Chicken Sandwich',
      description: 'https://photos.tryotter.com/menu-photos/d30c5b53-41c0-480f-b82b-2f7190d5ebe8.png',
      modifierGroupIds: [
        '10eaee68-9954-4037-b649-335035aa43bd',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 199,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ec3ca038-58e3-47cc-9830-db1953677adc',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9cc21c94-24cf-4e64-af10-8c2c79c4a714',
      photoIds: [],
      priceOverrides: [],
    },
    '4069f454-9b06-42c4-b888-9aa55796f32c': {
      name: 'MegaBox KSC Clasico + Pollo',
      description: 'https://photos.tryotter.com/menu-photos/34cb72a3-6321-4203-816b-eb9eba96463a.png',
      modifierGroupIds: [
        '13e00b68-d6ed-46c7-bc53-4a5c6322f29b',
        '612d3748-02f0-4a4f-8cbb-d90f3012301b',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '9474e192-39e1-42c3-a8cc-64549048bcfc',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 169,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:81d1dd70-8fd6-4666-9fcf-a4e345758760',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '4069f454-9b06-42c4-b888-9aa55796f32c',
      photoIds: [],
      priceOverrides: [],
    },
    '5a76a267-f8b4-483f-8de6-d85fc67a2f1c': {
      name: 'MegaBox KCS Spicy + Pollo',
      description: 'https://photos.tryotter.com/menu-photos/a7ef87e8-c0ae-40c6-a5f2-d779ae5897f9.png',
      modifierGroupIds: [
        'cf41f8bc-6705-4e07-87a2-439771c90994',
        '552d3a4b-b593-4204-a605-4ef17d49fafa',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '9474e192-39e1-42c3-a8cc-64549048bcfc',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 169,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:9d2493c6-c918-4444-bb06-9c4118b7c3c8',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '5a76a267-f8b4-483f-8de6-d85fc67a2f1c',
      photoIds: [],
      priceOverrides: [],
    },
    'e05a7788-6ff4-4669-955b-d356d5d91b63': {
      name: 'Megabox KCS BBQ Bacon Pieza',
      description: 'https://photos.tryotter.com/menu-photos/44f8648b-60e6-419d-92f2-5f2371c0f6db.png',
      modifierGroupIds: [
        'f1250c55-215f-41d2-9970-159761a374be',
        '2ad49e46-4a52-42a5-9ad3-1c369b23a295',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 219,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:78a9e7c0-66b3-4bd1-8a4c-f250ed024b02',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e05a7788-6ff4-4669-955b-d356d5d91b63',
      photoIds: [],
      priceOverrides: [],
    },
    'df9eba1e-4c92-477a-a504-d71a97d4e7f9': {
      name: ' Megabox KCS Chipotle Bacon Pieza',
      description: 'https://photos.tryotter.com/menu-photos/123a37e8-15e7-45db-a3a7-63c4c22f89dd.png',
      modifierGroupIds: [
        'b2a6b2e7-686e-4384-b6fb-cb2168376adb',
        '10eaee68-9954-4037-b649-335035aa43bd',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 219,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:da163c91-5e32-49c0-bece-a200f2d1aa45',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'df9eba1e-4c92-477a-a504-d71a97d4e7f9',
      photoIds: [],
      priceOverrides: [],
    },
    'a3570011-42aa-4c75-a2bb-6aef324d5340': {
      name: 'MegaBox KCS Clasico + KeTira',
      description: 'https://photos.tryotter.com/menu-photos/a9d3bb2a-4c4b-401b-84a3-519a4b1404ba.png',
      modifierGroupIds: [
        'c01dc887-5281-4f40-b181-ffec9be1410d',
        '612d3748-02f0-4a4f-8cbb-d90f3012301b',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '9474e192-39e1-42c3-a8cc-64549048bcfc',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 169,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ba9ebe3e-f1ce-4623-9c30-32c7ffd21678',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'a3570011-42aa-4c75-a2bb-6aef324d5340',
      photoIds: [],
      priceOverrides: [],
    },
    'fdba5123-ec71-4900-a904-ed46cc8b296f': {
      name: 'MegaBox KCS Spicy + KeTira',
      description: 'https://photos.tryotter.com/menu-photos/c47ac05c-ae97-4df8-9476-733aee682396.png',
      modifierGroupIds: [
        '53246779-8de1-4b33-bdbc-78393b03c8c7',
        '552d3a4b-b593-4204-a605-4ef17d49fafa',
        '200ac114-6576-44bb-9d44-6757ae437884',
        '9474e192-39e1-42c3-a8cc-64549048bcfc',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 169,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2112d838-bb5b-4df3-9ba5-fb76a7df75c2',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'fdba5123-ec71-4900-a904-ed46cc8b296f',
      photoIds: [],
      priceOverrides: [],
    },
    '499c5507-c1cb-48b9-9cbd-b3d1c87b1f19': {
      name: 'Megabox KCS BBQ Bacon Tira',
      description: 'https://photos.tryotter.com/menu-photos/7295bf62-8890-43ea-9e68-6438cc645af4.png',
      modifierGroupIds: [
        'af20ce82-5688-4cdc-b29d-cf792654275c',
        '2ad49e46-4a52-42a5-9ad3-1c369b23a295',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 219,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:56586e17-2e87-4ec6-aaa9-7913892a2dbe',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '499c5507-c1cb-48b9-9cbd-b3d1c87b1f19',
      photoIds: [],
      priceOverrides: [],
    },
    '31ab6c85-cbc3-4c1d-835f-12eaa8c2f903': {
      name: 'Megabox KCS Chipotle Bacon Tira',
      description: 'https://photos.tryotter.com/menu-photos/317808c1-bdbf-4110-bf83-315850459efc.png',
      modifierGroupIds: [
        'ded6a59d-256f-4f3a-bcdd-0fa884f39e32',
        '10eaee68-9954-4037-b649-335035aa43bd',
        'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
        '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
        'a333181a-b894-4532-8577-80cdd0e793e7',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 219,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7bb66b1a-9faf-46df-9fc7-71217670a00f',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '31ab6c85-cbc3-4c1d-835f-12eaa8c2f903',
      photoIds: [],
      priceOverrides: [],
    },
    '1559a820-35d3-4cf4-8e5f-5373ecd3a934': {
      name: ' Paquete Familiar 8 piezas',
      description: 'https://photos.tryotter.com/menu-photos/12239e80-2eaa-444c-8f2c-7e467772b318.png',
      modifierGroupIds: [
        '0125cae1-8cb1-4b23-a06f-4195e6f1e2dd',
        '86055b8c-f420-40e9-b651-67594fece188',
        '47675980-5d8a-4a54-b180-e47f784b02fc',
        '0f0ed32a-a61b-428e-a6b0-ff03dec1f6dd',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 279,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:9795f7ee-0418-4cc9-8e18-2d0c360f7541',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1559a820-35d3-4cf4-8e5f-5373ecd3a934',
      photoIds: [],
      priceOverrides: [],
    },
    '705193ce-fb40-41b4-a92f-3750329c01f6': {
      name: 'Paquete Familiar 10 piezas',
      description: 'https://photos.tryotter.com/menu-photos/128ba482-98b7-4823-b5e7-a1297cc1ad42.png',
      modifierGroupIds: [
        '6abd2279-7aa5-4b33-a451-9bebe21a3b64',
        '86055b8c-f420-40e9-b651-67594fece188',
        '47675980-5d8a-4a54-b180-e47f784b02fc',
        'b8441edf-0627-4c6f-814e-ccb47402454e',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 349,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:a963f98b-a1a3-4565-8389-041e1a47a816',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '705193ce-fb40-41b4-a92f-3750329c01f6',
      photoIds: [],
      priceOverrides: [],
    },
    '109739ce-bcf0-423d-83a4-bc71ef3f4001': {
      name: ' Paquete Familiar 12 piezas',
      description: 'https://photos.tryotter.com/menu-photos/10903db2-8edb-441b-a72f-409c3969059f.png',
      modifierGroupIds: [
        '526ed87b-ec3f-4f66-853c-5cb4eb60ddc6',
        '86055b8c-f420-40e9-b651-67594fece188',
        '47675980-5d8a-4a54-b180-e47f784b02fc',
        'b8441edf-0627-4c6f-814e-ccb47402454e',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 409,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7ec0139c-9c99-4b48-b94d-546f0e9f0c97',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '109739ce-bcf0-423d-83a4-bc71ef3f4001',
      photoIds: [],
      priceOverrides: [],
    },
    'c8074676-8ecf-4099-a645-213a75c3f05f': {
      name: ' Paquete Familiar 12 piezas + 4 Refrescos',
      description: 'https://photos.tryotter.com/menu-photos/aa95665d-9420-4e10-ab9c-cf2eb0afdef2.png',
      modifierGroupIds: [
        'b6e08b62-8788-4a6b-8ecb-e9d7d2fcd128',
        '86055b8c-f420-40e9-b651-67594fece188',
        '47675980-5d8a-4a54-b180-e47f784b02fc',
        '2ec39909-db7e-4da7-9749-2e9ba56fc65e',
        'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 449,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f79c6f6d-788d-4fab-a9b9-cb7354a127a3',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c8074676-8ecf-4099-a645-213a75c3f05f',
      photoIds: [],
      priceOverrides: [],
    },
    '50000898-522e-478a-8f89-31ebdaf120d6': {
      name: ' Paquete Familiar 14 piezas',
      description: 'https://photos.tryotter.com/menu-photos/8bda48ad-b6b9-41cc-97d5-09fc58ee40ce.png',
      modifierGroupIds: [
        'b27a934f-c125-4095-8c61-a2cf1bb9e1a1',
        '86055b8c-f420-40e9-b651-67594fece188',
        '8f498daa-6738-4e05-bff0-a20e34b246e8',
        '47675980-5d8a-4a54-b180-e47f784b02fc',
        'b8441edf-0627-4c6f-814e-ccb47402454e',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 439,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:85d7bb00-3733-4244-8a3c-ae2e0b3d777f',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '50000898-522e-478a-8f89-31ebdaf120d6',
      photoIds: [],
      priceOverrides: [],
    },
    'ebf3d8f8-9bd8-42a4-8ecb-25ed228bf826': {
      name: 'Paquete Familiar 16 piezas',
      description: 'https://photos.tryotter.com/menu-photos/cdffc209-d554-400c-b17b-0cfcd56c27d9.png',
      modifierGroupIds: [
        'be6407cb-df61-4158-88ba-6773ea1d8c8a',
        '86055b8c-f420-40e9-b651-67594fece188',
        '88f09bdb-119e-419a-8cb5-0def1e5318bd',
        '47675980-5d8a-4a54-b180-e47f784b02fc',
        'b8441edf-0627-4c6f-814e-ccb47402454e',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 489,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:16d6d52c-5baf-44d4-ba06-3a85a30e3f25',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'ebf3d8f8-9bd8-42a4-8ecb-25ed228bf826',
      photoIds: [],
      priceOverrides: [],
    },
    'ff779013-af20-47fd-953a-dee83aadcc7e': {
      name: 'Paquete Familiar 18 piezas',
      description: 'https://photos.tryotter.com/menu-photos/cdffc209-d554-400c-b17b-0cfcd56c27d9.png',
      modifierGroupIds: [
        '2361509a-101c-43ba-b10d-c3787f877e11',
        '86055b8c-f420-40e9-b651-67594fece188',
        '47675980-5d8a-4a54-b180-e47f784b02fc',
        '3d23f9c4-12b4-43f3-899b-36cf191b5c61',
        'b8441edf-0627-4c6f-814e-ccb47402454e',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 519,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:6473256c-8f02-4dcd-96f7-fec1ae23e2b1',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'ff779013-af20-47fd-953a-dee83aadcc7e',
      photoIds: [],
      priceOverrides: [],
    },
    'ece20580-d9b3-46c1-b3ec-8e8ad47dda81': {
      name: 'Family Play Garfield',
      description: '8 pz de pollo + 2 complementos familiares + 3 bisquets, elige entre: 2 popcorn med ó 2 kruncher ó 1 popcorn med+1 kruncher + 2 Libros Garfield',
      modifierGroupIds: [
        '52deb016-ef28-4b07-b352-6258e7390d6a',
        '28dd3c71-df46-4700-840a-46c2a6fd2e14',
        '6142eb3d-0c0d-4e92-9eea-b3363d2ad0de',
        '0f0ed32a-a61b-428e-a6b0-ff03dec1f6dd',
        '7f4495bc-af30-4216-8144-76d85ae5ff5e',
        '903a1dcd-bb71-45fa-ae38-d34a00c7c7f8',
        'fe0afe53-5364-4962-81ad-ba57cf7cd3fc',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 515,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:786212a1-cdb3-4357-82fc-3523a3812263',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'ece20580-d9b3-46c1-b3ec-8e8ad47dda81',
      photoIds: [],
      priceOverrides: [],
    },
    'ab62e683-58c7-4bd4-9f2a-c6781ebc61e3': {
      name: 'Kids Bucket Pollo',
      description: 'https://photos.tryotter.com/menu-photos/fa6a4159-9abd-4d18-a34d-2e5e2605563a.png',
      modifierGroupIds: [
        '2b72bca0-fbbe-48be-b135-e5dface3f137',
        '98b897fd-03f0-4f9d-8b1f-88e557504ae1',
        '5a063f22-69c6-4aa8-ad77-81939cf3a350',
        '030ca763-0373-4c54-9897-b510adeb88b9',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 119,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:646f7b75-7052-4abb-b21f-8cb365ca7815',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'ab62e683-58c7-4bd4-9f2a-c6781ebc61e3',
      photoIds: [],
      priceOverrides: [],
    },
    '24b8863f-6aba-4002-b29f-5387ceecf5c3': {
      name: ' Kids Bucket Tira',
      description: 'https://photos.tryotter.com/menu-photos/276c8001-53c8-4df0-99ae-ea59e859c204.png',
      modifierGroupIds: [
        '05df3ad7-3950-4faf-82b2-3c9558a8359b',
        '98b897fd-03f0-4f9d-8b1f-88e557504ae1',
        '5a063f22-69c6-4aa8-ad77-81939cf3a350',
        '030ca763-0373-4c54-9897-b510adeb88b9',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 119,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d058e5b7-efba-43f7-9231-e766a990637c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '24b8863f-6aba-4002-b29f-5387ceecf5c3',
      photoIds: [],
      priceOverrides: [],
    },
    'bd3774f6-83ac-40f9-9d69-3fa03827a230': {
      name: 'Kids Bucket Popcorn',
      description: 'https://photos.tryotter.com/menu-photos/c8df9a9f-c4f9-4186-bead-fde5a46b8abb.png',
      modifierGroupIds: [
        'b2085f22-b66e-4ad5-872c-a25e59e01c64',
        '98b897fd-03f0-4f9d-8b1f-88e557504ae1',
        '5a063f22-69c6-4aa8-ad77-81939cf3a350',
        '030ca763-0373-4c54-9897-b510adeb88b9',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 119,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ec73898e-4199-42f9-8337-39a203a07ab0',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'bd3774f6-83ac-40f9-9d69-3fa03827a230',
      photoIds: [],
      priceOverrides: [],
    },
    '188bacba-e991-40c5-af98-b659ff956f4a': {
      name: 'Kids Bucket Kruncher',
      description: 'https://photos.tryotter.com/menu-photos/8cc2f26f-1f05-4b77-8fc6-ea411e82d943.png',
      modifierGroupIds: [
        '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
        '98b897fd-03f0-4f9d-8b1f-88e557504ae1',
        '5a063f22-69c6-4aa8-ad77-81939cf3a350',
        '030ca763-0373-4c54-9897-b510adeb88b9',
        'e1536d30-9f16-4ab2-bccb-7d0027828b84',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 119,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:c7984438-2b80-417b-a5a6-37a9ca785f31',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '188bacba-e991-40c5-af98-b659ff956f4a',
      photoIds: [],
      priceOverrides: [],
    },
    '850d1a36-3ce6-442a-8a55-ef08d95307ca': {
      name: 'Big Kream 34oz',
      description: 'https://photos.tryotter.com/menu-photos/45f32207-6938-45f8-8b82-32179913f86a.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 59,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:af6e3814-0a48-489d-8765-ef3dcdb5864e',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '850d1a36-3ce6-442a-8a55-ef08d95307ca',
      photoIds: [],
      priceOverrides: [],
    },
    'b21e5b4a-2242-463c-8067-fc0f342ab462': {
      name: 'Big Kream 16oz',
      description: 'https://photos.tryotter.com/menu-photos/56970873-5ec7-4bd0-b8bb-a57f2106efc4.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 39,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:99857e19-7399-44f7-af48-95ab19eb2ab0',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'b21e5b4a-2242-463c-8067-fc0f342ab462',
      photoIds: [],
      priceOverrides: [],
    },
    '221a8ebf-adf0-4338-930f-a00a75b2bc04': {
      name: 'Big Kream 8oz',
      description: 'https://photos.tryotter.com/menu-photos/5eb96e4c-ac43-45cf-acec-cf5de92d72fe.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 29,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7dbbe9fe-57b3-400a-a4af-0cfd56ded046',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '221a8ebf-adf0-4338-930f-a00a75b2bc04',
      photoIds: [],
      priceOverrides: [],
    },
    '4c63041f-985b-48f6-9959-1f4cd58fbde0': {
      name: 'Sundae Caramelo',
      description: 'https://photos.tryotter.com/menu-photos/9a8c4734-c393-4c2c-a04a-31a3065e6f12.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 32,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:4a4a7533-649a-4e2d-bfb1-87a386795681',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '4c63041f-985b-48f6-9959-1f4cd58fbde0',
      photoIds: [],
      priceOverrides: [],
    },
    '7ca0153d-d631-4446-93de-b7552ed2e1c6': {
      name: ' Helado Sencillo',
      description: 'https://photos.tryotter.com/menu-photos/b26349c7-d642-4aac-9150-83c1f5825b7e.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 16,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f7b74a9c-420f-4951-a231-28f241f2c9de',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '7ca0153d-d631-4446-93de-b7552ed2e1c6',
      photoIds: [],
      priceOverrides: [],
    },
    '0a6224e4-1381-4664-8b3c-4e82ff2aac23': {
      name: 'Helado Doble',
      description: 'https://photos.tryotter.com/menu-photos/8a1a3ccf-51db-4ede-8525-78cc04d081ed.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 21,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:c585e9f2-5660-4395-b044-0a084fd4eac1',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '0a6224e4-1381-4664-8b3c-4e82ff2aac23',
      photoIds: [],
      priceOverrides: [],
    },
    '3eb4cf7d-c4b9-434f-a989-f5a19ffee9a3': {
      name: 'Cono Sencillo Hersheys',
      description: 'https://photos.tryotter.com/menu-photos/7ecbb118-e680-4af6-bb4a-0900e8ff5f9c.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 17,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:1dc932dc-3a63-4700-a774-0a8be10a0ab4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '3eb4cf7d-c4b9-434f-a989-f5a19ffee9a3',
      photoIds: [],
      priceOverrides: [],
    },
    '7e8affbf-0f6d-4dc1-a12f-7b99aced6ce0': {
      name: 'Cono Doble Hersheys',
      description: 'https://photos.tryotter.com/menu-photos/6be93259-c2e6-475b-97d2-c07687cf3570.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 22,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:8d5c0b1e-04c1-4cfa-ae51-6a6cad7a7d75',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '7e8affbf-0f6d-4dc1-a12f-7b99aced6ce0',
      photoIds: [],
      priceOverrides: [],
    },
    'f0707040-bb0a-472e-8732-3fd0f96401ac': {
      name: 'Big Kream Con Fresa 8oz',
      description: 'https://photos.tryotter.com/menu-photos/678738f5-6b4b-4514-85dd-7f9fcb96ab5c.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 39,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:405a0057-1fba-47d3-b525-ef15e206af0b',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'f0707040-bb0a-472e-8732-3fd0f96401ac',
      photoIds: [],
      priceOverrides: [],
    },
    '8b58d8c4-98a8-4f25-be51-75b31803d4f0': {
      name: 'Big Kream Con Caramelo 8oz',
      description: 'https://photos.tryotter.com/menu-photos/c78ba40d-b3fe-45ba-80fc-3d85a9038757.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 39,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:842934ad-90a4-4b85-a3b2-8a6353aa07bd',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '8b58d8c4-98a8-4f25-be51-75b31803d4f0',
      photoIds: [],
      priceOverrides: [],
    },
    '044fd586-7f5a-419c-bbb6-447243ef089c': {
      name: 'Big Kream Con Chocolate 8oz',
      description: 'https://photos.tryotter.com/menu-photos/6f16ebeb-b644-4bbc-869e-04f664ebc7ef.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 39,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f09eb1a5-1595-41a2-aa89-99bdfe2bd9ca',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '044fd586-7f5a-419c-bbb6-447243ef089c',
      photoIds: [],
      priceOverrides: [],
    },
    '61ab46f8-2875-4497-9dc8-86aa1796f432': {
      name: 'Big Kream Con Fresa 16oz',
      description: 'https://photos.tryotter.com/menu-photos/b7a70ef1-9575-42ca-9c19-0712a4e514e9.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 55,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:1ba51ab7-db99-4ab5-9576-7616323fc430',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '61ab46f8-2875-4497-9dc8-86aa1796f432',
      photoIds: [],
      priceOverrides: [],
    },
    'ebb52a68-4e41-45b0-916f-e9563a13c9c1': {
      name: 'BigKream con Caramelo 16oz',
      description: 'https://photos.tryotter.com/menu-photos/d4da8e80-e635-448f-b6fe-7a315cbaf7d9.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 55,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:a8b28c51-ad1a-420e-b968-4cfe7acb7b59',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'ebb52a68-4e41-45b0-916f-e9563a13c9c1',
      photoIds: [],
      priceOverrides: [],
    },
    '643c14a0-b308-41f8-8a89-fc8a73e6bc8c': {
      name: 'Big Kream Con Chocolate 16oz',
      description: 'https://photos.tryotter.com/menu-photos/93dc0126-34e9-42cc-b89d-4bf4d7ead1fb.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 55,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:c9b01b2c-0d09-44ce-80ff-5e26228fcd0d',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '643c14a0-b308-41f8-8a89-fc8a73e6bc8c',
      photoIds: [],
      priceOverrides: [],
    },
    '3c784368-6cb8-4d41-adc8-c6169e009b1b': {
      name: 'Big Kream Con Fresa 34oz',
      description: 'https://photos.tryotter.com/menu-photos/c5a147d8-917c-4dd5-a512-038a899305d4.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 79,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:4fd28db1-4690-4389-9bfa-d33fa1fa1891',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '3c784368-6cb8-4d41-adc8-c6169e009b1b',
      photoIds: [],
      priceOverrides: [],
    },
    '3eeb3ccb-8023-42ce-ae77-dc3c8bd7cb9c': {
      name: 'Big Kream Con Caramelo 34oz',
      description: 'https://photos.tryotter.com/menu-photos/11e179d4-2d49-4932-bdf3-83aa3d79598c.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 79,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:fd698691-ddce-439a-b972-e3fe071688aa',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '3eeb3ccb-8023-42ce-ae77-dc3c8bd7cb9c',
      photoIds: [],
      priceOverrides: [],
    },
    '928ee821-c006-4ba5-9178-0ab88d9c9264': {
      name: 'Paquete Mix',
      description: 'https://photos.tryotter.com/menu-photos/12ce5b58-def2-499a-a50a-2d9f370d5b3a.png',
      modifierGroupIds: [
        '66a6e755-9263-4b81-9f0d-1d09a50c58d4',
        'f75e1164-164c-469a-84f1-c1b27f89f153',
        '7018f910-7ee7-4a50-8589-a5dd1f11fed8',
        'bb8b9196-afb8-447c-bd33-e6196c827899',
        '86055b8c-f420-40e9-b651-67594fece188',
        '7394f73e-1de7-4346-bea3-9d4e37d54152',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 299,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:12d072bc-e29c-45df-a44f-d3e0c07b63cc',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '928ee821-c006-4ba5-9178-0ab88d9c9264',
      photoIds: [],
      priceOverrides: [],
    },
    '1f94b753-f846-41cf-a48f-066e8f6cef10': {
      name: 'Ke-Tiras Love',
      description: 'https://photos.tryotter.com/menu-photos/cfe40ce2-a957-4a74-a5ea-68afc36fcdd1.png',
      modifierGroupIds: [
        '9ff14aad-19f0-442e-abb2-ddb0b79c1490',
        '8ba53394-013f-4c34-a57a-99f0dbc99e74',
        '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 279,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:3cfa8b70-a086-4b4e-83a3-067788d32f54',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1f94b753-f846-41cf-a48f-066e8f6cef10',
      photoIds: [],
      priceOverrides: [],
    },
    '452ddc64-e768-497b-9736-6c61da8d3648': {
      name: 'Ke Tira Jalapeño',
      description: 'https://photos.tryotter.com/menu-photos/ff6ccbe6-885d-492e-90dd-95c11f7753c0.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f159c01b-03ef-49c4-8566-d20832630d15',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '452ddc64-e768-497b-9736-6c61da8d3648',
      photoIds: [],
      priceOverrides: [],
    },
    '04ee1b6b-ffa3-4747-a851-bb46cd582487': {
      name: 'Ke Tira',
      description: 'https://photos.tryotter.com/menu-photos/c4dc49e2-ac48-4557-94f3-022167620e96.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:739ae8d5-2811-4180-a5df-87ada3ef59ef',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '04ee1b6b-ffa3-4747-a851-bb46cd582487',
      photoIds: [],
      priceOverrides: [],
    },
    '29720bf7-e032-42bf-ae5f-93f9a41cdb05': {
      name: 'Ke Tiras BBQ',
      description: 'https://photos.tryotter.com/menu-photos/a37c7977-d4d0-4e29-8cb8-ce8dad5999df.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d752f09a-3417-401b-b4fb-4d8c5ed1e23e',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
      photoIds: [],
      priceOverrides: [],
    },
    'd958b994-bb95-4a17-83c9-a4f9c210e092': {
      name: 'Ke tira Habanero',
      description: 'https://photos.tryotter.com/menu-photos/c4dc49e2-ac48-4557-94f3-022167620e96.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:1df527c6-f717-4210-afc6-59221c24e358',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd958b994-bb95-4a17-83c9-a4f9c210e092',
      photoIds: [],
      priceOverrides: [],
    },
    '1f7f1510-2f3d-4eeb-82d3-222fdf72c640': {
      name: 'Ensalada Individual',
      description: 'https://photos.tryotter.com/menu-photos/769e3bbc-3e77-4a5b-9cdf-c3903c85171f.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f8213b6d-c130-4b3a-b73a-e1239fec59d4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1f7f1510-2f3d-4eeb-82d3-222fdf72c640',
      photoIds: [],
      priceOverrides: [],
    },
    'cca8d9d6-748a-4a7d-b7ca-cca374293d43': {
      name: 'Bisquet',
      description: 'https://photos.tryotter.com/menu-photos/1a1c62d0-f227-4163-a114-8fc3c9110ab1.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d2ee7a2c-121c-47dd-8e88-c238769f33f9',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'cca8d9d6-748a-4a7d-b7ca-cca374293d43',
      photoIds: [],
      priceOverrides: [],
    },
    '0eb18fa9-1b7d-4319-97be-175ef5c3037c': {
      name: 'Sin Gravy',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:230ac916-e1df-43e9-9778-6f6897c1d623',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '0eb18fa9-1b7d-4319-97be-175ef5c3037c',
      photoIds: [],
      priceOverrides: [],
    },
    'd3e72c57-13ed-4cc2-9711-1848fc4bf516': {
      name: 'Puré Individual',
      description: 'https://photos.tryotter.com/menu-photos/02cc79bf-bcfa-41d7-a11d-e7cbcd377c72.png',
      modifierGroupIds: [
        '61ecc84b-b46c-4d76-9203-5dc7061dbeae',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2e759f04-c2c1-4469-9b90-19fdb69aad95',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd3e72c57-13ed-4cc2-9711-1848fc4bf516',
      photoIds: [],
      priceOverrides: [],
    },
    'ae6307bb-624e-487d-88c8-ac8ff5c783ff': {
      name: 'Gravy Individual',
      description: 'https://photos.tryotter.com/menu-photos/19ae76bd-5bde-432b-bb82-a7da18225fc9.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:4e0bffa9-6127-4eaa-8b0a-9cef7d818f0c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'ae6307bb-624e-487d-88c8-ac8ff5c783ff',
      photoIds: [],
      priceOverrides: [],
    },
    '32ce6416-d104-4802-94a7-f95d19e95580': {
      name: 'Mac and Cheese Individual',
      description: 'https://photos.tryotter.com/menu-photos/8130ddee-f5cd-497e-a98e-b8be16d250c2.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 10,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:635a1fa2-0ac6-48b7-81d8-22354947e091',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '32ce6416-d104-4802-94a7-f95d19e95580',
      photoIds: [],
      priceOverrides: [],
    },
    'b5cec059-6411-46da-8985-66c7e13506a8': {
      name: 'Puré Individual',
      description: 'https://photos.tryotter.com/menu-photos/02cc79bf-bcfa-41d7-a11d-e7cbcd377c72.png',
      modifierGroupIds: [
        '61ecc84b-b46c-4d76-9203-5dc7061dbeae',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:17e6d562-d3f8-42d3-995c-5735410f3c78',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'b5cec059-6411-46da-8985-66c7e13506a8',
      photoIds: [],
      priceOverrides: [],
    },
    '157bd897-3fd3-421b-a5c0-f6e7c102fc03': {
      name: 'Bisquet',
      description: 'https://photos.tryotter.com/menu-photos/1a1c62d0-f227-4163-a114-8fc3c9110ab1.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:aff777d2-3861-425f-8a27-b0c3dd1f4104',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '157bd897-3fd3-421b-a5c0-f6e7c102fc03',
      photoIds: [],
      priceOverrides: [],
    },
    '025fad63-eedf-49f7-9867-344a01014eff': {
      name: 'Ensalada Individual',
      description: 'https://photos.tryotter.com/menu-photos/769e3bbc-3e77-4a5b-9cdf-c3903c85171f.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:4314ca7c-4758-4f79-b342-a15487994b44',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '025fad63-eedf-49f7-9867-344a01014eff',
      photoIds: [],
      priceOverrides: [],
    },
    'd309614b-ec52-4c9b-9e3f-69df5e6630d0': {
      name: 'Gravy Individual',
      description: 'https://photos.tryotter.com/menu-photos/19ae76bd-5bde-432b-bb82-a7da18225fc9.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:e1df5938-a5ef-4c39-a0f6-e05e09edad47',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd309614b-ec52-4c9b-9e3f-69df5e6630d0',
      photoIds: [],
      priceOverrides: [],
    },
    '324e1b9b-81bf-4efa-a2bf-11496f7acddd': {
      name: 'Mac and Chesse Individual',
      description: 'https://photos.tryotter.com/menu-photos/8130ddee-f5cd-497e-a98e-b8be16d250c2.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 10,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:bf2b539d-bff1-4ad5-a445-9129e648dba8',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '324e1b9b-81bf-4efa-a2bf-11496f7acddd',
      photoIds: [],
      priceOverrides: [],
    },
    'a008ef56-d5fd-4132-b6ce-a2df9b2d91a4': {
      name: 'Fanta Mediano',
      description: '',
      modifierGroupIds: [
        '56a5597a-f3f2-4dda-a108-70e0efb99282',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d7c006c1-5065-44d1-8f0d-74c5bb826d99',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'a008ef56-d5fd-4132-b6ce-a2df9b2d91a4',
      photoIds: [],
      priceOverrides: [],
    },
    '0cb78e66-6f5d-41ec-9f54-892ef97456c7': {
      name: 'Coca Light Mediano',
      description: '',
      modifierGroupIds: [
        '56a5597a-f3f2-4dda-a108-70e0efb99282',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ba048aa0-6a5e-4c2a-ab7e-d79da1a59570',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '0cb78e66-6f5d-41ec-9f54-892ef97456c7',
      photoIds: [],
      priceOverrides: [],
    },
    'b948f9c7-bf65-414c-817a-a648f6f95ac0': {
      name: 'Coca Cola Mediana',
      description: '',
      modifierGroupIds: [
        '56a5597a-f3f2-4dda-a108-70e0efb99282',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f9014b33-2884-46e9-98e7-48af727f2ad7',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'b948f9c7-bf65-414c-817a-a648f6f95ac0',
      photoIds: [],
      priceOverrides: [],
    },
    '3d3e1de4-d685-4791-945f-060159f4b973': {
      name: 'Manzana Mediano',
      description: '',
      modifierGroupIds: [
        '56a5597a-f3f2-4dda-a108-70e0efb99282',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:6b6f7b2c-af1f-4994-9c39-d4d88ffe17ca',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '3d3e1de4-d685-4791-945f-060159f4b973',
      photoIds: [],
      priceOverrides: [],
    },
    'cbb6fe45-0a9b-49c0-954f-a3c08f18684f': {
      name: 'Sprite Mediano',
      description: '',
      modifierGroupIds: [
        '56a5597a-f3f2-4dda-a108-70e0efb99282',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7d8244e0-3a5f-4d19-b602-748793b63fb0',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'cbb6fe45-0a9b-49c0-954f-a3c08f18684f',
      photoIds: [],
      priceOverrides: [],
    },
    'e5bd4193-8274-4088-bea0-cf7079208d64': {
      name: 'Sidral Mediano',
      description: '',
      modifierGroupIds: [
        '56a5597a-f3f2-4dda-a108-70e0efb99282',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:6e448b17-d360-4261-89f8-7c5cd5dcb6fc',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e5bd4193-8274-4088-bea0-cf7079208d64',
      photoIds: [],
      priceOverrides: [],
    },
    'd6a444e5-762e-4766-8e48-23dc526f1d8c': {
      name: 'CocaCola Sin Azúcar mediano',
      description: '',
      modifierGroupIds: [
        '56a5597a-f3f2-4dda-a108-70e0efb99282',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:08396d62-f12c-4e26-b7f0-94cda7c22699',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd6a444e5-762e-4766-8e48-23dc526f1d8c',
      photoIds: [],
      priceOverrides: [],
    },
    '5e7d655f-ce47-4619-9cab-af74e06d9433': {
      name: 'Agua',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2cbafc6a-4e3b-4a86-97b0-68b6c4dcf2d6',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '5e7d655f-ce47-4619-9cab-af74e06d9433',
      photoIds: [],
      priceOverrides: [],
    },
    '3abed434-fc95-46cf-af51-c34c24bfc778': {
      name: 'Puré Individual Delicia',
      description: 'https://photos.tryotter.com/menu-photos/02cc79bf-bcfa-41d7-a11d-e7cbcd377c72.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 25,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:40493c97-4bd4-463c-94eb-aa75224371a5',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '3abed434-fc95-46cf-af51-c34c24bfc778',
      photoIds: [],
      priceOverrides: [],
    },
    '3a88c786-6bb1-49a8-b94e-d7e1e1d9141f': {
      name: 'Ensalada Individual Delicia',
      description: 'https://photos.tryotter.com/menu-photos/769e3bbc-3e77-4a5b-9cdf-c3903c85171f.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 25,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:426f3514-6d3a-4066-8ad2-009bb2b40566',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '3a88c786-6bb1-49a8-b94e-d7e1e1d9141f',
      photoIds: [],
      priceOverrides: [],
    },
    'f0a3b865-7e8c-4828-81bf-49371a22c527': {
      name: 'Refresco Delicias',
      description: 'https://photos.tryotter.com/menu-photos/dd644925-af6b-4872-9d87-e5aed96d585f.jpeg',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 19,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:cb27e558-842d-41b0-9945-2cdf8bd8eef4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'f0a3b865-7e8c-4828-81bf-49371a22c527',
      photoIds: [],
      priceOverrides: [],
    },
    '9e7fa99c-fd79-4b38-883e-4a978b6bdb2d': {
      name: 'Sundae Fresa Delicia',
      description: 'https://photos.tryotter.com/menu-photos/f94989ab-0d16-4d54-8155-ec1dcb389094.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 25,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:67c0c40b-3a4a-4aa0-a141-4d7f0676870c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9e7fa99c-fd79-4b38-883e-4a978b6bdb2d',
      photoIds: [],
      priceOverrides: [],
    },
    'e199eabe-5be5-4d6f-8bb3-362a971b1d31': {
      name: 'Sundae Chocolate Delicia ',
      description: 'https://photos.tryotter.com/menu-photos/94dd5c2d-abe8-49d3-8ae5-9f4f9795e1a8.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 25,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:1915ed0c-23a0-4439-8de3-5b4d1bb57015',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e199eabe-5be5-4d6f-8bb3-362a971b1d31',
      photoIds: [],
      priceOverrides: [],
    },
    '1240adea-0ba6-4b65-8880-ea680fd2b18b': {
      name: 'Sundae Caramelo',
      description: 'https://photos.tryotter.com/menu-photos/9a8c4734-c393-4c2c-a04a-31a3065e6f12.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 25,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:e3cbbe57-3547-4b72-a29a-4693af395785',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1240adea-0ba6-4b65-8880-ea680fd2b18b',
      photoIds: [],
      priceOverrides: [],
    },
    'a2887014-fb31-444f-aa19-82052c49058e': {
      name: 'Bisquet',
      description: 'https://photos.tryotter.com/menu-photos/1a1c62d0-f227-4163-a114-8fc3c9110ab1.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 19,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:5e0262e6-bf80-492b-a76e-7c6b0d184bda',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'a2887014-fb31-444f-aa19-82052c49058e',
      photoIds: [],
      priceOverrides: [],
    },
    '9ca7ce2b-3f7d-4812-95c3-b23da15dd726': {
      name: 'Papas Delicias',
      description: 'https://photos.tryotter.com/menu-photos/2e1297a8-8ce2-4e45-ac4f-661c6c2822c3.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 25,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:8faac352-5be1-4af5-a23f-5aa49c64cbe6',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9ca7ce2b-3f7d-4812-95c3-b23da15dd726',
      photoIds: [],
      priceOverrides: [],
    },
    'c07fb68b-bf1c-450e-956c-c7e0945a8492': {
      name: 'Pieza Receta Secreta',
      description: 'https://photos.tryotter.com/menu-photos/8d7587f5-bd52-4c04-be3f-266d63cb1e47.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:16cffe35-0fb9-4773-b8ae-ce061e853e3e',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c07fb68b-bf1c-450e-956c-c7e0945a8492',
      photoIds: [],
      priceOverrides: [],
    },
    'd77e4451-7fd9-478c-a2b6-b6585b3fe29a': {
      name: 'Pieza BBQ',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:a20a9e10-5195-42df-84c5-789abca27420',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
      photoIds: [],
      priceOverrides: [],
    },
    '03362f6c-6f46-4e6b-9358-c76da69c2d11': {
      name: 'Pieza Jalapeño',
      description: 'https://photos.tryotter.com/menu-photos/2201673d-c969-47b2-ba64-5470defe2551.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:985af208-b57a-432f-a587-eda4d7f3fe7c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '03362f6c-6f46-4e6b-9358-c76da69c2d11',
      photoIds: [],
      priceOverrides: [],
    },
    '42d24971-7ccd-4b21-8b73-ec6abba82333': {
      name: 'Pieza Cruji',
      description: 'https://photos.tryotter.com/menu-photos/ccbcb27f-24cf-4717-bf27-4797e3caf9d2.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:30d27096-118c-450b-b99f-d9b2016819e6',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '42d24971-7ccd-4b21-8b73-ec6abba82333',
      photoIds: [],
      priceOverrides: [],
    },
    '4449b6cf-f75b-40e3-b6d3-c542217c9ace': {
      name: 'Piezas Habanero',
      description: 'https://photos.tryotter.com/menu-photos/c3334cb1-e8df-4690-9c1c-4ab774d63cf3.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:aa8ab725-c6f3-4c3f-a9d9-219391f29f4a',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      photoIds: [],
      priceOverrides: [],
    },
    '17a8c4cc-f91f-479d-b1ea-c083beb9bada': {
      name: 'Popcorn Mediano',
      description: 'https://photos.tryotter.com/menu-photos/76ab1e13-0d95-4c07-aa9e-a14ed4a54d79.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:a62b0d71-9338-41c9-92f2-af9d4158ca0d',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '17a8c4cc-f91f-479d-b1ea-c083beb9bada',
      photoIds: [],
      priceOverrides: [],
    },
    'e6b9c1a0-de9d-47c8-bd0d-de0de7955ad4': {
      name: '4 Refrescos',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 55,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:8f009aeb-17a6-424b-bc37-40ab13fd5ec3',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e6b9c1a0-de9d-47c8-bd0d-de0de7955ad4',
      photoIds: [],
      priceOverrides: [],
    },
    'fd6a6789-c029-4eb6-be74-4d04a2f31be3': {
      name: 'Bisquet',
      description: 'https://photos.tryotter.com/menu-photos/1a1c62d0-f227-4163-a114-8fc3c9110ab1.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 69,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:81f9b140-fc7c-4806-865e-24d84a62a620',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'fd6a6789-c029-4eb6-be74-4d04a2f31be3',
      photoIds: [],
      priceOverrides: [],
    },
    'ad4a63af-fb18-408d-9230-1bcc1c3d3515': {
      name: '3 Ke tiras',
      description: 'https://photos.tryotter.com/menu-photos/c4dc49e2-ac48-4557-94f3-022167620e96.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 85,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:72f9c8e6-595c-46de-b1c3-d08d149ae472',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'ad4a63af-fb18-408d-9230-1bcc1c3d3515',
      photoIds: [],
      priceOverrides: [],
    },
    'da7ba09d-735e-430c-b115-5fcc6a1361cc': {
      name: '2 Complementos',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 69,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7602172f-ce96-467b-8dc0-17ceb404ef1a',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'da7ba09d-735e-430c-b115-5fcc6a1361cc',
      photoIds: [],
      priceOverrides: [],
    },
    '1fe9bea5-ff73-4af9-b80e-1dea3c5a5356': {
      name: 'Puré y Mac and Cheese',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 99,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:22d3ae3e-bf49-4aa0-8c66-128985764bb4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1fe9bea5-ff73-4af9-b80e-1dea3c5a5356',
      photoIds: [],
      priceOverrides: [],
    },
    '6e56f684-1363-4579-b9e2-1d485291a131': {
      name: 'SIN-QUESO',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:78ee398a-f895-4b2b-911f-af9348043aa2',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '6e56f684-1363-4579-b9e2-1d485291a131',
      photoIds: [],
      priceOverrides: [],
    },
    'e1e1570d-4f28-40f3-86cc-5af44aba18f1': {
      name: 'SIN ADEREZO',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:0e53a6aa-5117-4154-a52b-96b2141084c6',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e1e1570d-4f28-40f3-86cc-5af44aba18f1',
      photoIds: [],
      priceOverrides: [],
    },
    '7fe0ebe6-c9ac-4633-8be4-0a3674927c38': {
      name: 'SIN LECHUGA',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2c991d39-e0d0-401b-81d9-a502130a8dcc',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '7fe0ebe6-c9ac-4633-8be4-0a3674927c38',
      photoIds: [],
      priceOverrides: [],
    },
    'dbe81970-206c-4943-a330-7f0f2a58899f': {
      name: 'SIN JITOMATE',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ca3a9a7b-4cf8-4f4c-950b-9bd5cf7f1c72',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'dbe81970-206c-4943-a330-7f0f2a58899f',
      photoIds: [],
      priceOverrides: [],
    },
    '66128db1-f2a5-4654-8422-4b533f8fbf62': {
      name: 'SIN- SALSA',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:86e5ee90-8fad-4c0b-ada3-c359d40f4128',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '66128db1-f2a5-4654-8422-4b533f8fbf62',
      photoIds: [],
      priceOverrides: [],
    },
    'd2fa7c28-af76-495f-bb3c-bd224b89556e': {
      name: 'SIN CATSUP',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:35ed7ecd-8239-48d9-9fab-ec068ab110da',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd2fa7c28-af76-495f-bb3c-bd224b89556e',
      photoIds: [],
      priceOverrides: [],
    },
    'c406a088-c888-4bae-af55-89e9631cbafb': {
      name: 'SIN TOCINO',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:c77750d7-4379-4798-92f2-7549c5b57d46',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c406a088-c888-4bae-af55-89e9631cbafb',
      photoIds: [],
      priceOverrides: [],
    },
    'cf23af20-61de-4e5e-9546-9549561972be': {
      name: 'SIN-MOSTAZA',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:45e6e29e-6ad4-4d57-817c-f0dd7eb8f77a',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'cf23af20-61de-4e5e-9546-9549561972be',
      photoIds: [],
      priceOverrides: [],
    },
    '26775abc-68d0-40b7-b417-61c05de87048': {
      name: 'SIN-PEPINILLOS',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:022ad23f-11e4-4a71-8120-44da2c173c86',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '26775abc-68d0-40b7-b417-61c05de87048',
      photoIds: [],
      priceOverrides: [],
    },
    'dc73d66c-f002-4d40-ba10-f91ad4f98c68': {
      name: 'SIN-CEBOLLA',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2b5f0a5f-4aa6-4adb-906b-5c731f43cbd5',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'dc73d66c-f002-4d40-ba10-f91ad4f98c68',
      photoIds: [],
      priceOverrides: [],
    },
    'dcc56fd6-bc81-402c-99b3-8fb4abfacb28': {
      name: 'Ke Tiras Burger BBQ',
      description: '',
      modifierGroupIds: [
        'e0f3ddc9-899c-4530-b8ba-19aae05253eb',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7a3a13b1-223a-4ef2-ae54-73b21cd8641a',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'dcc56fd6-bc81-402c-99b3-8fb4abfacb28',
      photoIds: [],
      priceOverrides: [],
    },
    '449c2cb0-f159-4f08-9570-862931f886a9': {
      name: 'Burger Jalapeño',
      description: '',
      modifierGroupIds: [
        'e0f3ddc9-899c-4530-b8ba-19aae05253eb',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:e5ac8603-0e47-47c1-87cc-b32bf014c72b',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '449c2cb0-f159-4f08-9570-862931f886a9',
      photoIds: [],
      priceOverrides: [],
    },
    '82fb9339-0fcd-4710-8a33-76b653f4d264': {
      name: 'Burger',
      description: '',
      modifierGroupIds: [
        'e0f3ddc9-899c-4530-b8ba-19aae05253eb',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7847df56-788d-4741-a002-0daa3d0d935c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '82fb9339-0fcd-4710-8a33-76b653f4d264',
      photoIds: [],
      priceOverrides: [],
    },
    '1f02bcaf-f69d-432c-bf50-0cb6aa27f507': {
      name: 'Papas Medianas',
      description: 'https://photos.tryotter.com/menu-photos/2e1297a8-8ce2-4e45-ac4f-661c6c2822c3.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7faa1b34-57c8-4843-8860-3d9faa021e00',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1f02bcaf-f69d-432c-bf50-0cb6aa27f507',
      photoIds: [],
      priceOverrides: [],
    },
    '7ecfca4f-f9fa-4344-b571-92d3253ea4e0': {
      name: 'Coca Cola Grande',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d72b4426-fc22-4acf-ba33-6e95a989c9b0',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '7ecfca4f-f9fa-4344-b571-92d3253ea4e0',
      photoIds: [],
      priceOverrides: [],
    },
    '61d67080-cc4f-431f-917f-5a9da6ab45e0': {
      name: 'Coca Light Grande',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:c23657f0-66b3-40a0-b6d3-057bcd0333fe',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '61d67080-cc4f-431f-917f-5a9da6ab45e0',
      photoIds: [],
      priceOverrides: [],
    },
    '8dbac772-639b-4264-99f1-4e6f4426948d': {
      name: 'Coca Cola SinAzucar',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:8c099515-cb37-42e9-bb8d-1bba6c854f90',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '8dbac772-639b-4264-99f1-4e6f4426948d',
      photoIds: [],
      priceOverrides: [],
    },
    '1d1135d5-7574-4184-98ac-62a3d4cd87b8': {
      name: 'Sprite Sin Azucar Grande',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:91c6d4ac-3db0-4406-9fad-5d1d25205579',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1d1135d5-7574-4184-98ac-62a3d4cd87b8',
      photoIds: [],
      priceOverrides: [],
    },
    '5cc10b34-a27e-4718-bcaa-11ab95631748': {
      name: 'Fanta Grande',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:10e49e9a-5802-49a4-8e51-b17fad24389d',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '5cc10b34-a27e-4718-bcaa-11ab95631748',
      photoIds: [],
      priceOverrides: [],
    },
    '2122df0f-7ec1-48d8-96eb-78ecfa7af247': {
      name: 'Sidral Grande',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2471b6c8-ed18-4d09-9db1-df44f961b0ac',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '2122df0f-7ec1-48d8-96eb-78ecfa7af247',
      photoIds: [],
      priceOverrides: [],
    },
    '30acf0f5-ba6e-45c6-88a6-cc98f6b8e7bf': {
      name: 'Filete Jalapeño',
      description: '',
      modifierGroupIds: [
        '6f3e3205-575a-44be-be06-5b9ab198e090',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:0425c7b5-b6a0-4689-a33c-7f66447e63f9',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '30acf0f5-ba6e-45c6-88a6-cc98f6b8e7bf',
      photoIds: [],
      priceOverrides: [],
    },
    'a2dfc3e0-359d-40f4-8281-7fdeab182962': {
      name: 'Filete BBQ',
      description: '',
      modifierGroupIds: [
        '6f3e3205-575a-44be-be06-5b9ab198e090',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d07fbaaf-05f5-476e-9b3b-871840c663ef',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'a2dfc3e0-359d-40f4-8281-7fdeab182962',
      photoIds: [],
      priceOverrides: [],
    },
    'e2ed506e-1a9f-460f-bf1a-8f3661111804': {
      name: 'Big Krunch Paquete',
      description: '',
      modifierGroupIds: [
        '6f3e3205-575a-44be-be06-5b9ab198e090',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:5c2f85c0-4c37-4275-9165-a55bd939dc98',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e2ed506e-1a9f-460f-bf1a-8f3661111804',
      photoIds: [],
      priceOverrides: [],
    },
    '21408f41-8c69-42d9-b417-4ee7e734571b': {
      name: 'Chicken Sandwich Clasico',
      description: '',
      modifierGroupIds: [
        '126d57e3-295f-4112-84f1-179b413a84c0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:67bf9373-f9f9-40c0-a79e-9def114517f3',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '21408f41-8c69-42d9-b417-4ee7e734571b',
      photoIds: [],
      priceOverrides: [],
    },
    '2606f49b-3764-4e5e-bd36-a974e935400e': {
      name: 'Kentucky Chicken Sandwich Spicy Mayo',
      description: 'https://photos.tryotter.com/menu-photos/55b4b9bf-324a-4f5f-a9ce-3e3c2bb4430f.png',
      modifierGroupIds: [
        '126d57e3-295f-4112-84f1-179b413a84c0',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:597f3313-8a22-432a-88d6-54b9ef006bcf',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '2606f49b-3764-4e5e-bd36-a974e935400e',
      photoIds: [],
      priceOverrides: [],
    },
    '0de05053-2960-4d59-805a-54f0dbb853c0': {
      name: 'Hamburguesa BBQ Bacon',
      description: 'https://photos.tryotter.com/menu-photos/14ee1fb3-7c78-431d-b4e6-515b7cda7099.png',
      modifierGroupIds: [
        '1f3d8bd7-003d-4ece-84f6-f5957389e8e9',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f89314c5-54a3-42a8-8ff4-bc67e71e75a2',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '0de05053-2960-4d59-805a-54f0dbb853c0',
      photoIds: [],
      priceOverrides: [],
    },
    '0b4dcc4f-314f-47f7-b0ce-704afa82fd5f': {
      name: 'Hamburguesa Chipotle',
      description: 'https://photos.tryotter.com/menu-photos/6c0f2e9b-d540-473f-a854-0fcef0cbdc68.png',
      modifierGroupIds: [
        '1f3d8bd7-003d-4ece-84f6-f5957389e8e9',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:aa112c2a-362a-4455-8920-5c329882f781',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '0b4dcc4f-314f-47f7-b0ce-704afa82fd5f',
      photoIds: [],
      priceOverrides: [],
    },
    'f1bdf221-2292-44be-a4d6-94d765dd7511': {
      name: 'Ensalada de col Familiar',
      description: 'https://photos.tryotter.com/menu-photos/912c3342-49d4-4f7d-8845-9ad65648d20f.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:58b55e75-8ad2-4a8a-9c6c-39d5be3d54b4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'f1bdf221-2292-44be-a4d6-94d765dd7511',
      photoIds: [],
      priceOverrides: [],
    },
    '82ad272a-e1ba-4aeb-aa27-9676212c8dc7': {
      name: 'Puré de Papa Familiar',
      description: 'https://photos.tryotter.com/menu-photos/192d76e7-1041-49ba-b1ca-a66f3fcaea76.png',
      modifierGroupIds: [
        '61ecc84b-b46c-4d76-9203-5dc7061dbeae',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:cfe3a097-7a0a-4795-b06e-b6fc1d2759f9',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
      photoIds: [],
      priceOverrides: [],
    },
    '1aa8cfc6-b2cb-4e68-9626-4fc924474df3': {
      name: 'Gravy Familiar',
      description: 'https://photos.tryotter.com/menu-photos/dfdc5c34-7b65-404f-b7eb-d94b217dc565.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f093d484-a45d-41b4-a42c-0fb9b47cd5f4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1aa8cfc6-b2cb-4e68-9626-4fc924474df3',
      photoIds: [],
      priceOverrides: [],
    },
    'adb776ef-2d8e-4e10-b72f-013258da6679': {
      name: 'Mac N Cheese Familiar',
      description: 'https://photos.tryotter.com/menu-photos/fdf081f1-84eb-4b93-8b1f-e1305f3aedf5.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 29,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d74b95a5-b71e-443a-abb8-70b2090d801f',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'adb776ef-2d8e-4e10-b72f-013258da6679',
      photoIds: [],
      priceOverrides: [],
    },
    'eb956ca3-1d20-486f-a84f-b23b5fab8af4': {
      name: 'Gravy Familiar',
      description: 'https://photos.tryotter.com/menu-photos/dfdc5c34-7b65-404f-b7eb-d94b217dc565.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7eaa2939-1a76-4bce-9b7c-d254a38ecf02',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'eb956ca3-1d20-486f-a84f-b23b5fab8af4',
      photoIds: [],
      priceOverrides: [],
    },
    '5605b489-adeb-43ea-856f-0ead993a9a3e': {
      name: 'Mac N Cheese Familiar',
      description: 'https://photos.tryotter.com/menu-photos/fdf081f1-84eb-4b93-8b1f-e1305f3aedf5.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 29,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:2f960f26-1eb2-47d0-becb-c0fe55649532',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '5605b489-adeb-43ea-856f-0ead993a9a3e',
      photoIds: [],
      priceOverrides: [],
    },
    'c7e500aa-00d6-4cff-a155-cc175a0f68cc': {
      name: 'Bisquets',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:5af131b1-0a54-4896-b32e-ce68623ee639',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c7e500aa-00d6-4cff-a155-cc175a0f68cc',
      photoIds: [],
      priceOverrides: [],
    },
    'eeeae120-4fa1-4b08-b420-ff759c5701fe': {
      name: 'Bisquets',
      description: 'https://photos.tryotter.com/menu-photos/1a1c62d0-f227-4163-a114-8fc3c9110ab1.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:57e09242-bc08-4c7f-ac64-d6182ad2879f',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'eeeae120-4fa1-4b08-b420-ff759c5701fe',
      photoIds: [],
      priceOverrides: [],
    },
    '319d9145-377d-41f9-a86f-8b9a28b7c75b': {
      name: 'Papas Familiares',
      description: 'https://photos.tryotter.com/menu-photos/b331601a-1334-449d-9519-ef14a6aa327d.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ee5b3a17-1183-4926-8a48-e35e3fef876c',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '319d9145-377d-41f9-a86f-8b9a28b7c75b',
      photoIds: [],
      priceOverrides: [],
    },
    '54d901a9-2879-4041-9376-fc1de58ae97c': {
      name: 'Papas Familiares',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:e43ff525-4f1d-4956-b68e-79c78ceff0b6',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '54d901a9-2879-4041-9376-fc1de58ae97c',
      photoIds: [],
      priceOverrides: [],
    },
    '8a085cce-9fff-455d-99b5-292bb1d455ed': {
      name: 'Popcorn',
      description: 'https://photos.tryotter.com/menu-photos/76ab1e13-0d95-4c07-aa9e-a14ed4a54d79.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d44324a0-a43c-4ed0-81f8-de13e8a85211',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '8a085cce-9fff-455d-99b5-292bb1d455ed',
      photoIds: [],
      priceOverrides: [],
    },
    '5ec1779a-2721-47fb-8be4-ad267eaa9d58': {
      name: 'Kruncher',
      description: 'https://photos.tryotter.com/menu-photos/fcc23bd3-5ee0-401b-bfaa-9b22e641d2b6.png',
      modifierGroupIds: [
        '5fc40dd4-eb1d-4757-a3d8-03138dc25a54',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:d53f8d88-4f90-487c-bb9b-b69e43d37d5e',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '5ec1779a-2721-47fb-8be4-ad267eaa9d58',
      photoIds: [],
      priceOverrides: [],
    },
    '60cee497-1288-498a-88be-6ec4773c5d0e': {
      name: '2 Big Kream 80z',
      description: 'https://photos.tryotter.com/menu-photos/49eed55d-f0ce-4d3c-994b-a913d8cb5b77.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:bfb0ae22-3ea7-4f42-8763-b2fe974c1a13',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '60cee497-1288-498a-88be-6ec4773c5d0e',
      photoIds: [],
      priceOverrides: [],
    },
    '523eff4b-a231-4d32-9861-15dc7ac5bb80': {
      name: '2 pay',
      description: 'https://photos.tryotter.com/menu-photos/c044253f-b26e-4ef3-ae0f-3010dff84d05.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:74e0eb5f-f1f5-49e9-b328-dc6ba894f392',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '523eff4b-a231-4d32-9861-15dc7ac5bb80',
      photoIds: [],
      priceOverrides: [],
    },
    '20bbe3ac-41c6-431d-a7df-83add69f6240': {
      name: '2 Libro Garfield',
      description: 'https://photos.tryotter.com/menu-photos/0e2661c8-ac74-4bdf-85f2-2ed81641c799.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f5f11615-d705-4ec6-8fb4-c7ab7f851564',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '20bbe3ac-41c6-431d-a7df-83add69f6240',
      photoIds: [],
      priceOverrides: [],
    },
    'b6bad79e-6fea-4c20-9625-7ecc56636141': {
      name: 'Puré Kids',
      description: 'https://photos.tryotter.com/menu-photos/02cc79bf-bcfa-41d7-a11d-e7cbcd377c72.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:90ac3eb8-0194-4804-bcfa-61ebc3a51e3a',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'b6bad79e-6fea-4c20-9625-7ecc56636141',
      photoIds: [],
      priceOverrides: [],
    },
    '8b62c80c-a9e4-4862-b35b-d6be0483fffc': {
      name: 'Ensalada Kids',
      description: 'https://photos.tryotter.com/menu-photos/769e3bbc-3e77-4a5b-9cdf-c3903c85171f.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:795296a6-ff86-42ef-abd3-3359e1d075ac',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '8b62c80c-a9e4-4862-b35b-d6be0483fffc',
      photoIds: [],
      priceOverrides: [],
    },
    '1ae8dd4d-7a84-4e74-96b8-f674cbd9656e': {
      name: 'Bisquet Kids',
      description: 'https://photos.tryotter.com/menu-photos/1a1c62d0-f227-4163-a114-8fc3c9110ab1.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:7e42816d-00f8-4698-bb9b-02e6ccaf2eb7',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1ae8dd4d-7a84-4e74-96b8-f674cbd9656e',
      photoIds: [],
      priceOverrides: [],
    },
    'e02c6294-4b31-4ef7-8095-403f391cbfb3': {
      name: 'Mac and Chesse med',
      description: 'https://photos.tryotter.com/menu-photos/8130ddee-f5cd-497e-a98e-b8be16d250c2.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 10,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:249cfac2-86b8-41c9-b5f1-1fcbbc8cfdb5',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'e02c6294-4b31-4ef7-8095-403f391cbfb3',
      photoIds: [],
      priceOverrides: [],
    },
    'd65a4aca-f232-470a-a1bf-005e0c7e9bc3': {
      name: 'Agua Natural',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:863304c1-b53b-498d-b6a1-29b5bdd82da4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'd65a4aca-f232-470a-a1bf-005e0c7e9bc3',
      photoIds: [],
      priceOverrides: [],
    },
    '1550bba6-5993-4a65-a068-63bd645728b0': {
      name: 'Jugo de Manzana',
      description: '',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:f277d590-6ac5-491e-a1e0-08615d4efd7b',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '1550bba6-5993-4a65-a068-63bd645728b0',
      photoIds: [],
      priceOverrides: [],
    },
    '6c5ee5ae-2f18-4cc7-9983-9d4d46e7e651': {
      name: 'Big Kream 80z',
      description: 'https://photos.tryotter.com/menu-photos/49eed55d-f0ce-4d3c-994b-a913d8cb5b77.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:0a15a320-99c3-4ea5-b16d-daaa7e8c3a11',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '6c5ee5ae-2f18-4cc7-9983-9d4d46e7e651',
      photoIds: [],
      priceOverrides: [],
    },
    '9e58a47c-1b18-4881-a06b-7c9a73c41803': {
      name: 'Pay',
      description: 'https://photos.tryotter.com/menu-photos/c044253f-b26e-4ef3-ae0f-3010dff84d05.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:238c3c17-f913-4ace-887f-a48bb6f94b3d',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '9e58a47c-1b18-4881-a06b-7c9a73c41803',
      photoIds: [],
      priceOverrides: [],
    },
    'c2bbc171-1bac-42af-9e78-eb7efc135bf6': {
      name: 'Libro Garfield',
      description: 'https://photos.tryotter.com/menu-photos/0e2661c8-ac74-4bdf-85f2-2ed81641c799.png',
      modifierGroupIds: [],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:deff9475-f8d5-497c-94a5-4d78d98666b7',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: 'c2bbc171-1bac-42af-9e78-eb7efc135bf6',
      photoIds: [],
      priceOverrides: [],
    },
    '6c374932-1612-448e-b3df-ff1ac16ce8d3': {
      name: 'Hamburguesa Kruncher',
      description: '',
      modifierGroupIds: [
        '5fc40dd4-eb1d-4757-a3d8-03138dc25a54',
      ],
      status: {
        saleStatus: 'FOR_SALE',
      },
      price: {
        currencyCode: 'MXN',
        amount: 0,
      },
      skuDetails: {
        skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:3c974227-1543-4c99-a1a1-db70793492f4',
        barcodes: [],
        dietaryClassifications: [],
        allergenClassifications: [],
        additives: [],
        storageRequirements: [],
        containsAlcohol: false,
      },
      additionalCharges: [],
      id: '6c374932-1612-448e-b3df-ff1ac16ce8d3',
      photoIds: [],
      priceOverrides: [],
    },
  },
  modifierGroups: {
    '27ea4584-58fc-4b49-a7b8-1eeb85eb8ce0': {
      name: 'Elige Receta 3 Tiras',
      minimumSelections: 0,
      maximumSelections: 3,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: '27ea4584-58fc-4b49-a7b8-1eeb85eb8ce0',
    },
    '61ecc84b-b46c-4d76-9203-5dc7061dbeae': {
      name: 'Cambio Puré',
      minimumSelections: 1,
      maximumSelections: 1,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '0eb18fa9-1b7d-4319-97be-175ef5c3037c',
      ],
      id: '61ecc84b-b46c-4d76-9203-5dc7061dbeae',
    },
    'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c': {
      name: 'Elige tu complemento Individual #1',
      minimumSelections: 1,
      maximumSelections: 4,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '1f7f1510-2f3d-4eeb-82d3-222fdf72c640',
        'cca8d9d6-748a-4a7d-b7ca-cca374293d43',
        '2d68e68e-56fc-4381-a766-30c75e378394',
        'd3e72c57-13ed-4cc2-9711-1848fc4bf516',
        'ae6307bb-624e-487d-88c8-ac8ff5c783ff',
        '32ce6416-d104-4802-94a7-f95d19e95580',
      ],
      id: 'e5a93ac7-6db2-496d-9ad2-d572e8e77a6c',
    },
    '200ac114-6576-44bb-9d44-6757ae437884': {
      name: 'Complemento Individual #1',
      minimumSelections: 2,
      maximumSelections: 3,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'b5cec059-6411-46da-8985-66c7e13506a8',
        '157bd897-3fd3-421b-a5c0-f6e7c102fc03',
        '025fad63-eedf-49f7-9867-344a01014eff',
        'd309614b-ec52-4c9b-9e3f-69df5e6630d0',
        '324e1b9b-81bf-4efa-a2bf-11496f7acddd',
      ],
      id: '200ac114-6576-44bb-9d44-6757ae437884',
    },
    '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab': {
      name: 'Bisquet original',
      minimumSelections: 0,
      maximumSelections: 1,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '157bd897-3fd3-421b-a5c0-f6e7c102fc03',
      ],
      id: '5aeafe84-16c7-47a2-ad8e-762aedf7d2ab',
    },
    'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486': {
      name: 'Escoge tu refresco Familiar',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'a008ef56-d5fd-4132-b6ce-a2df9b2d91a4',
        '0cb78e66-6f5d-41ec-9f54-892ef97456c7',
        'b948f9c7-bf65-414c-817a-a648f6f95ac0',
        '3d3e1de4-d685-4791-945f-060159f4b973',
        'cbb6fe45-0a9b-49c0-954f-a3c08f18684f',
        'e5bd4193-8274-4088-bea0-cf7079208d64',
        'd6a444e5-762e-4766-8e48-23dc526f1d8c',
        '5e7d655f-ce47-4619-9cab-af74e06d9433',
      ],
      id: 'f8be1aa9-e994-48a8-8b4f-7fd1a65ac486',
    },
    'e1536d30-9f16-4ab2-bccb-7d0027828b84': {
      name: 'Agrega una Delicia (Opcional)',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '3abed434-fc95-46cf-af51-c34c24bfc778',
        '3a88c786-6bb1-49a8-b94e-d7e1e1d9141f',
        'f0a3b865-7e8c-4828-81bf-49371a22c527',
        '9e7fa99c-fd79-4b38-883e-4a978b6bdb2d',
        'e199eabe-5be5-4d6f-8bb3-362a971b1d31',
        '1240adea-0ba6-4b65-8880-ea680fd2b18b',
        '13939570-0bac-4d1a-9223-21f24a93a0ab',
        'a2887014-fb31-444f-aa19-82052c49058e',
        '9ca7ce2b-3f7d-4812-95c3-b23da15dd726',
      ],
      id: 'e1536d30-9f16-4ab2-bccb-7d0027828b84',
    },
    '1ea23726-4892-4f4c-9766-7116ae38b255': {
      name: 'Elige Receta 2 Tiras',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: '1ea23726-4892-4f4c-9766-7116ae38b255',
    },
    '26f4fa1f-9212-4c04-812e-32d041b749e9': {
      name: 'Elige Receta 4 Tiras',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: '26f4fa1f-9212-4c04-812e-32d041b749e9',
    },
    '55be13c9-a9c6-4c65-b2f4-673abcedfe68': {
      name: 'Elige Receta 2 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '55be13c9-a9c6-4c65-b2f4-673abcedfe68',
    },
    '01e23ddf-c1f0-4454-883d-1d1ef1259a9e': {
      name: 'Elige Receta 3 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '01e23ddf-c1f0-4454-883d-1d1ef1259a9e',
    },
    'eef8c173-df1a-431f-ab8b-67af30025604': {
      name: 'Elige Receta 4 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'eef8c173-df1a-431f-ab8b-67af30025604',
    },
    'ec2914e2-0413-4480-b0ed-6f091921dc7e': {
      name: 'Elige receta Pieza CNS (6)',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'ec2914e2-0413-4480-b0ed-6f091921dc7e',
    },
    '9b815fca-a541-423a-89ed-6f4cb35ed9ea': {
      name: 'Elige receta CNS 6 Ke-tiras',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: '9b815fca-a541-423a-89ed-6f4cb35ed9ea',
    },
    '7cced3a6-4dc6-4356-ab95-97cd81fe03af': {
      name: 'Elige Receta Pieza CNS Popcorn',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '7cced3a6-4dc6-4356-ab95-97cd81fe03af',
    },
    'b2085f22-b66e-4ad5-872c-a25e59e01c64': {
      name: 'Popcorn Mediano',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '17a8c4cc-f91f-479d-b1ea-c083beb9bada',
      ],
      id: 'b2085f22-b66e-4ad5-872c-a25e59e01c64',
    },
    'b29280f2-95aa-4f7a-a844-007fa51eb2bd': {
      name: 'Elige receta Pieza CNS - MIX',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'b29280f2-95aa-4f7a-a844-007fa51eb2bd',
    },
    '7018f910-7ee7-4a50-8589-a5dd1f11fed8': {
      name: 'Elige Receta Tira',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
      ],
      id: '7018f910-7ee7-4a50-8589-a5dd1f11fed8',
    },
    '1c08b972-1111-469c-b034-fe0c8e2c0e56': {
      name: 'Elige Receta 8 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '1c08b972-1111-469c-b034-fe0c8e2c0e56',
    },
    '0cfc73a7-2145-434c-a161-c1dc6e0473f0': {
      name: 'Delicias',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '5f499c46-afb8-4e6e-be8d-5723e105e14e',
        'e6b9c1a0-de9d-47c8-bd0d-de0de7955ad4',
        'fd6a6789-c029-4eb6-be74-4d04a2f31be3',
        'ad4a63af-fb18-408d-9230-1bcc1c3d3515',
        'da7ba09d-735e-430c-b115-5fcc6a1361cc',
        '1fe9bea5-ff73-4af9-b80e-1dea3c5a5356',
      ],
      id: '0cfc73a7-2145-434c-a161-c1dc6e0473f0',
    },
    '04b8c82a-a942-4d7e-a8c6-3ca828de4a19': {
      name: 'Cambios Opcionales Hamburguesa',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '6e56f684-1363-4579-b9e2-1d485291a131',
        'e1e1570d-4f28-40f3-86cc-5af44aba18f1',
        '7fe0ebe6-c9ac-4633-8be4-0a3674927c38',
        'dbe81970-206c-4943-a330-7f0f2a58899f',
        '66128db1-f2a5-4654-8422-4b533f8fbf62',
        'd2fa7c28-af76-495f-bb3c-bd224b89556e',
        'c406a088-c888-4bae-af55-89e9631cbafb',
        'cf23af20-61de-4e5e-9546-9549561972be',
        '26775abc-68d0-40b7-b417-61c05de87048',
        'dc73d66c-f002-4d40-ba10-f91ad4f98c68',
      ],
      id: '04b8c82a-a942-4d7e-a8c6-3ca828de4a19',
    },
    'bc5a4c5e-1f93-41ec-b9e1-6ecb04e67f88': {
      name: 'Cambio Burger KETIRAS',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'dcc56fd6-bc81-402c-99b3-8fb4abfacb28',
        '449c2cb0-f159-4f08-9570-862931f886a9',
        '82fb9339-0fcd-4710-8a33-76b653f4d264',
      ],
      id: 'bc5a4c5e-1f93-41ec-b9e1-6ecb04e67f88',
    },
    '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381': {
      name: 'Complemento Individual #2',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '1f02bcaf-f69d-432c-bf50-0cb6aa27f507',
        'fb03d98b-51d0-429e-bd29-9474146661ea',
      ],
      id: '80b7e6fd-3961-4a5a-9e60-05ed5d5ec381',
    },
    'a333181a-b894-4532-8577-80cdd0e793e7': {
      name: 'Escoge tu refresco',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'b948f9c7-bf65-414c-817a-a648f6f95ac0',
        '0cb78e66-6f5d-41ec-9f54-892ef97456c7',
        'd6a444e5-762e-4766-8e48-23dc526f1d8c',
        'a008ef56-d5fd-4132-b6ce-a2df9b2d91a4',
        'cbb6fe45-0a9b-49c0-954f-a3c08f18684f',
        '3d3e1de4-d685-4791-945f-060159f4b973',
        'e5bd4193-8274-4088-bea0-cf7079208d64',
        '5e7d655f-ce47-4619-9cab-af74e06d9433',
      ],
      id: 'a333181a-b894-4532-8577-80cdd0e793e7',
    },
    '9474e192-39e1-42c3-a8cc-64549048bcfc': {
      name: 'Complemento Individual #3',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '1f02bcaf-f69d-432c-bf50-0cb6aa27f507',
      ],
      id: '9474e192-39e1-42c3-a8cc-64549048bcfc',
    },
    '292e340f-04b5-44db-b14d-d64c5ec279d9': {
      name: 'Elige Refresco Mediano',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'b948f9c7-bf65-414c-817a-a648f6f95ac0',
        '0cb78e66-6f5d-41ec-9f54-892ef97456c7',
        'd6a444e5-762e-4766-8e48-23dc526f1d8c',
        'a008ef56-d5fd-4132-b6ce-a2df9b2d91a4',
        'cbb6fe45-0a9b-49c0-954f-a3c08f18684f',
        'e5bd4193-8274-4088-bea0-cf7079208d64',
        '3d3e1de4-d685-4791-945f-060159f4b973',
      ],
      id: '292e340f-04b5-44db-b14d-d64c5ec279d9',
    },
    'a6729d64-bf10-40fb-9294-d5d910c1550b': {
      name: 'Elige Sabor Refresco Grande',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '7ecfca4f-f9fa-4344-b571-92d3253ea4e0',
        '61d67080-cc4f-431f-917f-5a9da6ab45e0',
        '8dbac772-639b-4264-99f1-4e6f4426948d',
        '1d1135d5-7574-4184-98ac-62a3d4cd87b8',
        '5cc10b34-a27e-4718-bcaa-11ab95631748',
        '2122df0f-7ec1-48d8-96eb-78ecfa7af247',
      ],
      id: 'a6729d64-bf10-40fb-9294-d5d910c1550b',
    },
    '3fa76239-c55f-4a33-8d9b-5f933f2f2572': {
      name: 'Cambios Hamburguesa',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '30acf0f5-ba6e-45c6-88a6-cc98f6b8e7bf',
        'a2dfc3e0-359d-40f4-8281-7fdeab182962',
        'e2ed506e-1a9f-460f-bf1a-8f3661111804',
      ],
      id: '3fa76239-c55f-4a33-8d9b-5f933f2f2572',
    },
    'b750587e-038a-49e1-b170-41ef2f4a0710': {
      name: 'Elige tu receta',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
      ],
      id: 'b750587e-038a-49e1-b170-41ef2f4a0710',
    },
    '612d3748-02f0-4a4f-8cbb-d90f3012301b': {
      name: 'Selecciona tu Sandwich',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '21408f41-8c69-42d9-b417-4ee7e734571b',
      ],
      id: '612d3748-02f0-4a4f-8cbb-d90f3012301b',
    },
    '552d3a4b-b593-4204-a605-4ef17d49fafa': {
      name: 'Selecciona tu Sandwich',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '2606f49b-3764-4e5e-bd36-a974e935400e',
      ],
      id: '552d3a4b-b593-4204-a605-4ef17d49fafa',
    },
    '2ad49e46-4a52-42a5-9ad3-1c369b23a295': {
      name: 'Hamburguesa BBQ Bacon',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '0de05053-2960-4d59-805a-54f0dbb853c0',
      ],
      id: '2ad49e46-4a52-42a5-9ad3-1c369b23a295',
    },
    '10eaee68-9954-4037-b649-335035aa43bd': {
      name: 'Hamburguesa Chipotle',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '0b4dcc4f-314f-47f7-b0ce-704afa82fd5f',
      ],
      id: '10eaee68-9954-4037-b649-335035aa43bd',
    },
    '13e00b68-d6ed-46c7-bc53-4a5c6322f29b': {
      name: 'Elige Receta 1 Pieza',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '13e00b68-d6ed-46c7-bc53-4a5c6322f29b',
    },
    'cf41f8bc-6705-4e07-87a2-439771c90994': {
      name: 'Elige Receta Pieza',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'cf41f8bc-6705-4e07-87a2-439771c90994',
    },
    'f1250c55-215f-41d2-9970-159761a374be': {
      name: 'Elige tu receta 1 Pieza',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'f1250c55-215f-41d2-9970-159761a374be',
    },
    'b2a6b2e7-686e-4384-b6fb-cb2168376adb': {
      name: 'Elige tu receta',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'b2a6b2e7-686e-4384-b6fb-cb2168376adb',
    },
    'c01dc887-5281-4f40-b181-ffec9be1410d': {
      name: 'Elige Receta 1 Tira.',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: 'c01dc887-5281-4f40-b181-ffec9be1410d',
    },
    '53246779-8de1-4b33-bdbc-78393b03c8c7': {
      name: 'Elige Receta Tira',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: '53246779-8de1-4b33-bdbc-78393b03c8c7',
    },
    'af20ce82-5688-4cdc-b29d-cf792654275c': {
      name: 'Elige Receta 1 Tira',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: 'af20ce82-5688-4cdc-b29d-cf792654275c',
    },
    'ded6a59d-256f-4f3a-bcdd-0fa884f39e32': {
      name: 'Elige Receta Tiras',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: 'ded6a59d-256f-4f3a-bcdd-0fa884f39e32',
    },
    '0125cae1-8cb1-4b23-a06f-4195e6f1e2dd': {
      name: 'Elige Receta 8 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '0125cae1-8cb1-4b23-a06f-4195e6f1e2dd',
    },
    '86055b8c-f420-40e9-b651-67594fece188': {
      name: 'Elige tu complemento familiar #1',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        '1aa8cfc6-b2cb-4e68-9626-4fc924474df3',
        'adb776ef-2d8e-4e10-b72f-013258da6679',
      ],
      id: '86055b8c-f420-40e9-b651-67594fece188',
    },
    '47675980-5d8a-4a54-b180-e47f784b02fc': {
      name: 'Elige tu complemento familiar #2',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        'eb956ca3-1d20-486f-a84f-b23b5fab8af4',
        '5605b489-adeb-43ea-856f-0ead993a9a3e',
      ],
      id: '47675980-5d8a-4a54-b180-e47f784b02fc',
    },
    '0f0ed32a-a61b-428e-a6b0-ff03dec1f6dd': {
      name: '3 Bisquets Originales',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c7e500aa-00d6-4cff-a155-cc175a0f68cc',
      ],
      id: '0f0ed32a-a61b-428e-a6b0-ff03dec1f6dd',
    },
    '6abd2279-7aa5-4b33-a451-9bebe21a3b64': {
      name: 'Elige Receta 10 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '6abd2279-7aa5-4b33-a451-9bebe21a3b64',
    },
    'b8441edf-0627-4c6f-814e-ccb47402454e': {
      name: '4 Bisquets Originales',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'eeeae120-4fa1-4b08-b420-ff759c5701fe',
      ],
      id: 'b8441edf-0627-4c6f-814e-ccb47402454e',
    },
    '526ed87b-ec3f-4f66-853c-5cb4eb60ddc6': {
      name: 'Elige Receta 12 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '526ed87b-ec3f-4f66-853c-5cb4eb60ddc6',
    },
    'b6e08b62-8788-4a6b-8ecb-e9d7d2fcd128': {
      name: 'Elige Receta 12 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'b6e08b62-8788-4a6b-8ecb-e9d7d2fcd128',
    },
    '2ec39909-db7e-4da7-9749-2e9ba56fc65e': {
      name: '4 Bisquets Originales',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'eeeae120-4fa1-4b08-b420-ff759c5701fe',
      ],
      id: '2ec39909-db7e-4da7-9749-2e9ba56fc65e',
    },
    'b27a934f-c125-4095-8c61-a2cf1bb9e1a1': {
      name: 'Elige Receta 14 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'b27a934f-c125-4095-8c61-a2cf1bb9e1a1',
    },
    '8f498daa-6738-4e05-bff0-a20e34b246e8': {
      name: 'Elige tu complemento familiar #3',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        '1aa8cfc6-b2cb-4e68-9626-4fc924474df3',
        'adb776ef-2d8e-4e10-b72f-013258da6679',
      ],
      id: '8f498daa-6738-4e05-bff0-a20e34b246e8',
    },
    'be6407cb-df61-4158-88ba-6773ea1d8c8a': {
      name: 'Elige Receta 16 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: 'be6407cb-df61-4158-88ba-6773ea1d8c8a',
    },
    '88f09bdb-119e-419a-8cb5-0def1e5318bd': {
      name: 'Elige tu complemento familiar #3',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        '1aa8cfc6-b2cb-4e68-9626-4fc924474df3',
        'adb776ef-2d8e-4e10-b72f-013258da6679',
      ],
      id: '88f09bdb-119e-419a-8cb5-0def1e5318bd',
    },
    '2361509a-101c-43ba-b10d-c3787f877e11': {
      name: 'Elige Receta 18 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '2361509a-101c-43ba-b10d-c3787f877e11',
    },
    '3d23f9c4-12b4-43f3-899b-36cf191b5c61': {
      name: 'Elige tu complemento familiar #3',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        '1aa8cfc6-b2cb-4e68-9626-4fc924474df3',
        'adb776ef-2d8e-4e10-b72f-013258da6679',
      ],
      id: '3d23f9c4-12b4-43f3-899b-36cf191b5c61',
    },
    '52deb016-ef28-4b07-b352-6258e7390d6a': {
      name: 'Elige Pieza Family Play',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '52deb016-ef28-4b07-b352-6258e7390d6a',
    },
    '28dd3c71-df46-4700-840a-46c2a6fd2e14': {
      name: 'Elige Complemento Family Play #1',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        '1aa8cfc6-b2cb-4e68-9626-4fc924474df3',
        'adb776ef-2d8e-4e10-b72f-013258da6679',
        '319d9145-377d-41f9-a86f-8b9a28b7c75b',
      ],
      id: '28dd3c71-df46-4700-840a-46c2a6fd2e14',
    },
    '6142eb3d-0c0d-4e92-9eea-b3363d2ad0de': {
      name: 'Elige Complemento Family Play #2',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        'eb956ca3-1d20-486f-a84f-b23b5fab8af4',
        '5605b489-adeb-43ea-856f-0ead993a9a3e',
        '54d901a9-2879-4041-9376-fc1de58ae97c',
      ],
      id: '6142eb3d-0c0d-4e92-9eea-b3363d2ad0de',
    },
    '7f4495bc-af30-4216-8144-76d85ae5ff5e': {
      name: 'Complemento Family #1',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '8a085cce-9fff-455d-99b5-292bb1d455ed',
        '5ec1779a-2721-47fb-8be4-ad267eaa9d58',
      ],
      id: '7f4495bc-af30-4216-8144-76d85ae5ff5e',
    },
    '903a1dcd-bb71-45fa-ae38-d34a00c7c7f8': {
      name: 'Complemento Family #2',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '8a085cce-9fff-455d-99b5-292bb1d455ed',
        '5ec1779a-2721-47fb-8be4-ad267eaa9d58',
      ],
      id: '903a1dcd-bb71-45fa-ae38-d34a00c7c7f8',
    },
    'fe0afe53-5364-4962-81ad-ba57cf7cd3fc': {
      name: 'Elige tu favorito family',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '60cee497-1288-498a-88be-6ec4773c5d0e',
        '523eff4b-a231-4d32-9861-15dc7ac5bb80',
        '20bbe3ac-41c6-431d-a7df-83add69f6240',
      ],
      id: 'fe0afe53-5364-4962-81ad-ba57cf7cd3fc',
    },
    '2b72bca0-fbbe-48be-b135-e5dface3f137': {
      name: 'Elige tu receta KIDS Pollo',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '2b72bca0-fbbe-48be-b135-e5dface3f137',
    },
    '98b897fd-03f0-4f9d-8b1f-88e557504ae1': {
      name: 'Elige tu Complemento Kids',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'b6bad79e-6fea-4c20-9625-7ecc56636141',
        '8b62c80c-a9e4-4862-b35b-d6be0483fffc',
        '1ae8dd4d-7a84-4e74-96b8-f674cbd9656e',
        '2d68e68e-56fc-4381-a766-30c75e378394',
        'e02c6294-4b31-4ef7-8095-403f391cbfb3',
      ],
      id: '98b897fd-03f0-4f9d-8b1f-88e557504ae1',
    },
    '5a063f22-69c6-4aa8-ad77-81939cf3a350': {
      name: 'Escoge tu refresco',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'a008ef56-d5fd-4132-b6ce-a2df9b2d91a4',
        '0cb78e66-6f5d-41ec-9f54-892ef97456c7',
        'b948f9c7-bf65-414c-817a-a648f6f95ac0',
        '3d3e1de4-d685-4791-945f-060159f4b973',
        'cbb6fe45-0a9b-49c0-954f-a3c08f18684f',
        'e5bd4193-8274-4088-bea0-cf7079208d64',
        'd6a444e5-762e-4766-8e48-23dc526f1d8c',
        'd65a4aca-f232-470a-a1bf-005e0c7e9bc3',
        '1550bba6-5993-4a65-a068-63bd645728b0',
      ],
      id: '5a063f22-69c6-4aa8-ad77-81939cf3a350',
    },
    '030ca763-0373-4c54-9897-b510adeb88b9': {
      name: 'Elige tu favorito',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '6c5ee5ae-2f18-4cc7-9983-9d4d46e7e651',
        '9e58a47c-1b18-4881-a06b-7c9a73c41803',
        'c2bbc171-1bac-42af-9e78-eb7efc135bf6',
      ],
      id: '030ca763-0373-4c54-9897-b510adeb88b9',
    },
    '05df3ad7-3950-4faf-82b2-3c9558a8359b': {
      name: 'Elige Receta KIDS 1 Tira.',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: '05df3ad7-3950-4faf-82b2-3c9558a8359b',
    },
    '66a6e755-9263-4b81-9f0d-1d09a50c58d4': {
      name: 'Elige Receta 5 Piezas',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '42d24971-7ccd-4b21-8b73-ec6abba82333',
        'c07fb68b-bf1c-450e-956c-c7e0945a8492',
        'd77e4451-7fd9-478c-a2b6-b6585b3fe29a',
        '03362f6c-6f46-4e6b-9358-c76da69c2d11',
        '4449b6cf-f75b-40e3-b6d3-c542217c9ace',
      ],
      id: '66a6e755-9263-4b81-9f0d-1d09a50c58d4',
    },
    'f75e1164-164c-469a-84f1-c1b27f89f153': {
      name: 'Hamburguesa Kruncher',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '6c374932-1612-448e-b3df-ff1ac16ce8d3',
      ],
      id: 'f75e1164-164c-469a-84f1-c1b27f89f153',
    },
    'bb8b9196-afb8-447c-bd33-e6196c827899': {
      name: 'Elige Receta 2 Tiras',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
      ],
      id: 'bb8b9196-afb8-447c-bd33-e6196c827899',
    },
    '7394f73e-1de7-4346-bea3-9d4e37d54152': {
      name: 'Elige tu complemento familiar MIX',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        'f1bdf221-2292-44be-a4d6-94d765dd7511',
        '82ad272a-e1ba-4aeb-aa27-9676212c8dc7',
        '1aa8cfc6-b2cb-4e68-9626-4fc924474df3',
        'adb776ef-2d8e-4e10-b72f-013258da6679',
      ],
      id: '7394f73e-1de7-4346-bea3-9d4e37d54152',
    },
    '9ff14aad-19f0-442e-abb2-ddb0b79c1490': {
      name: 'Elige Receta KE-TIRAS LOVE',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '452ddc64-e768-497b-9736-6c61da8d3648',
        '04ee1b6b-ffa3-4747-a851-bb46cd582487',
        '29720bf7-e032-42bf-ae5f-93f9a41cdb05',
        'd958b994-bb95-4a17-83c9-a4f9c210e092',
      ],
      id: '9ff14aad-19f0-442e-abb2-ddb0b79c1490',
    },
    '8ba53394-013f-4c34-a57a-99f0dbc99e74': {
      name: 'Papas Familiar',
      minimumSelections: 0,
      maximumSelections: 0,
      maxPerModifierSelectionQuantity: 1,
      itemIds: [
        '319d9145-377d-41f9-a86f-8b9a28b7c75b',
      ],
      id: '8ba53394-013f-4c34-a57a-99f0dbc99e74',
    },
  },
  photos: {},
};

export const VOUCHER_ITEM: HubsterMenuPublishItem = {
  id: '0bd3d4b4-225a-4e85-b91a-5e098894eae5',
  name: '35 Nuggets',
  photoIds: ['https://photos.tryotter.com/menu-photos/6db1e285-b09d-4705-a90b-84132b6c958a.png'],
  price: {
    currencyCode: 'MXN',
    amount: 315,
  },
  priceOverrides: [],
  status: {
    saleStatus: SalesStatus.FOR_SALE,
  },
  description: '',
  modifierGroupIds: [
    '610d8796-3a79-4302-9402-6ca23e9f52dd',
    '7d86eda7-e971-44e2-afd8-de0a603eefcc',
  ],
  skuDetails: {
    skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ee01b915-cdae-477e-abd3-be53d8401018',
    barcodes: [],
    dietaryClassifications: [],
    allergenClassifications: [],
    containsAlcohol: false,
    servings: { min: 0, max: 10 },
    nutritionalInfo: {
      energyKcal: { low: 0, high: 10 },
    },
  },
  additionalCharges: [],
  tax: {
    percentageValue: {
      decimalValue: 0,
    },
    isValueAddedTax: true,
  },
};
