import { SpecialKeys } from '../enums/specialKeys';

export const KEYS = {
  1: { keyName: 'q-key', normalValue: 'Q', numericValue: '1' },
  2: { keyName: 'w-key', normalValue: 'W', numericValue: '2' },
  3: { keyName: 'e-key', normalValue: 'E', numericValue: '3' },
  4: { keyName: 'r-key', normalValue: 'R', numericValue: '4' },
  5: { keyName: 't-key', normalValue: 'T', numericValue: '5' },
  6: { keyName: 'y-key', normalValue: 'Y', numericValue: '6' },
  7: { keyName: 'u-key', normalValue: 'U', numericValue: '7' },
  8: { keyName: 'i-key', normalValue: 'I', numericValue: '8' },
  9: { keyName: 'o-key', normalValue: 'O', numericValue: '9' },
  10: { keyName: 'p-key', normalValue: 'P', numericValue: '0' },
  11: { keyName: 'a-key', normalValue: 'A', numericValue: '*' },
  12: { keyName: 's-key', normalValue: 'S', numericValue: '/' },
  13: { keyName: 'd-key', normalValue: 'D', numericValue: ':' },
  14: { keyName: 'f-key', normalValue: 'F', numericValue: ';' },
  15: { keyName: 'g-key', normalValue: 'G', numericValue: '(' },
  16: { keyName: 'h-key', normalValue: 'H', numericValue: ')' },
  17: { keyName: 'j-key', normalValue: 'J', numericValue: '$' },
  18: { keyName: 'k-key', normalValue: 'K', numericValue: '&' },
  19: { keyName: 'l-key', normalValue: 'L', numericValue: '@' },
  20: { keyName: 'ñ-key', normalValue: 'Ñ', numericValue: '"' },
  21: { keyName: 'num-modifier-key', normalValue: SpecialKeys.NUMERIC, numericValue: 'ABC' },
  22: { keyName: 'z-key', normalValue: 'Z', numericValue: '.' },
  23: { keyName: 'x-key', normalValue: 'X', numericValue: ',' },
  24: { keyName: 'c-key', normalValue: 'C', numericValue: '?' },
  25: { keyName: 'v-key', normalValue: 'V', numericValue: '!' },
  26: { keyName: 'b-key', normalValue: 'B', numericValue: "'" },
  27: { keyName: 'n-key', normalValue: 'N', numericValue: '-' },
  28: { keyName: 'm-key', normalValue: 'M', numericValue: '_' },
  29: { keyName: 'delete-key', normalValue: SpecialKeys.DELETE, numericValue: SpecialKeys.DELETE },
  30: { keyName: 'space-key', normalValue: SpecialKeys.SPACE, numericValue: SpecialKeys.SPACE },
};
