export function keyboardDelete(inputRef: HTMLInputElement, text: string): string {
  const selectionStart = (inputRef.selectionStart ?? 0) - 1;
  if (selectionStart < 0) return text;
  const newText = text.slice(0, selectionStart) + text.slice(selectionStart + 1);
  setTimeout(() => {
    if (!inputRef.value) return;
    inputRef.focus();
    inputRef.setSelectionRange(selectionStart, selectionStart);
  });

  return newText;
}

export function keyboardAddLetter(inputRef: HTMLInputElement, text: string, letter: string): string {
  const selectionStart = inputRef.selectionStart ?? 0;
  const newText = text.slice(0, selectionStart) + letter + text.slice(selectionStart);
  setTimeout(() => {
    if (!inputRef.value) return;
    inputRef.focus();
    inputRef.setSelectionRange(selectionStart + 1, selectionStart + 1);
  });

  return newText;
}
