import { LanguageLocales } from '@/modules/language/types';
import { IKioskLanguage } from '@/modules/language/interfaces';

export const useLanguageStore = defineStore('language', () => {
  // State
  const languages = ref<IKioskLanguage[]>([]);
  const currentLanguage = ref<LanguageLocales>('es');
  const isLanguageEditable = ref<boolean>(false);

  // Actions
  function setLanguage(value: LanguageLocales) {
    currentLanguage.value = value;
    i18n.global.locale.value = value;
  }

  function setLanguages(list: IKioskLanguage[]) {
    languages.value = list;
  }

  function setEditableValue(value: boolean) {
    isLanguageEditable.value = value;
  }

  return {
    languages,
    currentLanguage,
    isLanguageEditable,
    setLanguage,
    setLanguages,
    setEditableValue,
  };
});
