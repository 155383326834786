import { HubsterOrderModifier } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { ModifierDescriptor } from '@slabcode/hubster-modifiers-utils';

export function parseModifiers(
  modifiers?: ModifierDescriptor[],
): HubsterOrderModifier[] {
  return (modifiers || [])
    .filter((modifier) =>
      modifier.items.some((item) => item.selected || item.quantity))
    .map((modifier) =>
      modifier.items
        .filter((item) => item.selected || item.quantity)
        .map((item) => ({
          quantity: item.quantity,
          id: item.itemMeta.id,
          name: item.itemMeta.name,
          price: item.itemMeta.price.amount,
          modifiers: parseModifiers(item.modifiers),
          groupId: modifier.modifierMeta.id,
          groupName: modifier.modifierMeta.name,
        })))
    .flat();
}
