<script setup lang="ts">
import { PropType } from 'vue';
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

const props = defineProps({
  item: {
    type: Object as PropType<ItemDescriptor>,
    required: true,
  },
  modifierDecimals: {
    type: Number,
    required: false,
    default: 2,
  },
  showDetails: {
    type: Boolean,
    required: false,
    default: true,
  },
  containerClass: {
    type: String,
    required: false,
    default: '',
  },
  displayCheckbox: {
    type: Boolean,
  },
});

const emit = defineEmits(['select', 'customize', 'saveChanges', 'add', 'sub']);

const menuStore = useMenuStore();
const metadataStore = useMetadataStore();
const { cardRadiusClass, counterStyle } = storeToRefs(metadataStore);
const { item } = toRefs(props);
const readonly = isReadonlyItem(item.value);

const modifierPrice = computed(() => {
  const { amount } = props.item.itemMeta.price;

  return amount !== 0
    ? formatCurrency(
      menuStore.productsCurrency,
      amount ?? 0,
      props.modifierDecimals,
    )
    : null;
});

function chooseModifier() {
  emit('select', props.item);
}

function onCustomizeClick() {
  emit('customize', props.item);
}
</script>

<template>
  <div
    :class="[containerClass, cardRadiusClass]"
    class="flex items-center justify-between w-full gap-8 px-5 flex-nowrap border-[3px] border-transparent my-1"
  >
    <div
      class="flex items-center gap-6 list-data"
      :class="[
        item.canBeCustomized ? 'w-3/4' : 'w-full',
        { 'opacity-50': !item.available },
      ]"
      @click="chooseModifier()"
      @keydown.enter="chooseModifier()"
    >
      <div class="overflow-hidden rounded-md modifier-image w-14 h-14">
        <KioskImage
          v-if="item.itemMeta.photoIds[0]"
          :src="item.itemMeta.photoIds[0]"
          hide-border
          loader-size="small"
          class="image-size"
        />

        <div
          v-else
          class="grid w-full h-full bg-neutral-200 place-content-center"
        >
          <span class="text-2xl icon icon-image-list" />
        </div>
      </div>

      <span class="relative w-2/3 text-3xl text-left name">
        <div v-if="!item.available" class="text-2xl font-bold">
          {{ $t("PRODUCTS.OUT_OF_STOCK") }}
        </div>
        {{ item.itemMeta.name }}
      </span>

      <span
        v-show="modifierPrice"
        class="w-1/4 text-4xl leading-6 tracking-tight text-right text-neutral-400"
      >
        +{{ modifierPrice }}
      </span>
    </div>

    <KioskButton
      v-if="item.canBeCustomized"
      text-size="extra-small"
      class="z-10 mx-auto"
      color="primary"
      :disabled="item.quantity <= 0 || !item.available"
      @click="onCustomizeClick()"
    >
      {{ !item.available ? $t("NOT_AVAILABLE") : $t("CUSTOMIZE") }}
    </KioskButton>

    <KioskCounterV2
      v-if="item.hasNumericInput && !readonly"
      class="mx-auto"
      :disable-increase="item.disableAddButton"
      :disable-decrease="item.disableSubButton"
      :disabled="!item.available || item.disabled"
      :button-style="counterStyle"
      :quantity="item.quantity"
      @add="$emit('add', props.item)"
      @sub="$emit('sub', props.item)"
    />

    <template v-if="readonly && item.hasNumericInput">
      <span
        class="flex justify-center w-full text-4xl font-medium"
      >
        {{ item.quantity }}
      </span>
    </template>

    <template v-if="displayCheckbox">
      <label :for="item.itemMeta.id" />

      <input
        type="checkbox"
        class="h-7 accent-primary-600 w-7"
        :id="item.itemMeta.id"
        :checked="item.selected"
        @click="chooseModifier()"
      />
    </template>
  </div>
</template>

<style scoped></style>
