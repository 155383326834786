<script setup lang="ts">
const props = defineProps({
  position: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  showCollapse: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue', 'closed']);
// TODO: Update collapse computed and watchEffect
const collapse = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: boolean) {
    emit('update:modelValue', newValue);
  },
});

watchEffect(() => {
  if (props.disabled) {
    collapse.value = true;
  }
});

const formattedPosition = computed(() => Number(props.position + 1).toLocaleString('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false,
}));

function beforeMount() {
  collapse.value = props.modelValue;

  if (props.disabled) {
    collapse.value = true;
  }
}

onBeforeMount(() => beforeMount());
</script>

<template>
  <div class="kfc-collapsable">
    <div
      class="z-20 flex items-center justify-between px-12 py-8 bg-white"
      :class="{ 'opacity-50': disabled }"
    >
      <div class="flex items-center gap-9">
        <span class="position bg-primary-600">{{ formattedPosition }}</span>

        <h2 class="font-light leading-8 text-balance text-[34px] min-w-[32rem]">
          {{ title }}
        </h2>
      </div>

      <div class="w-full h-[1px] bg-neutral-500 mx-8" />

      <div v-if="showCollapse" class="text-[34px] flex gap-2 items-start">
        <slot name="breadcrumbs" />

        <button
          type="button"
          class="collapse-button"
          :disabled="disabled"
          :data-collapse-target="`collapse-${position}`"
          @click="emit('update:modelValue', !collapse)"
        >
          <span
            class="transition-all icon icon-arrow-down"
            :class="{ 'rotate-animation': collapse }"
          />
        </button>
      </div>
    </div>

    <div class="overflow-hidden">
      <SlideTransition
        enter-animation="slideInDown"
        leave-animation="fadeOutUp"
      >
        <div
          v-if="!collapse"
          class="w-full"
        >
          <slot />
        </div>
      </SlideTransition>
    </div>
  </div>
</template>

<style scoped>
.position {
  @apply grid place-items-center p-3 text-white rounded font-bold leading-6 tracking-tight mb-[6px] text-4xl;
}

.collapse-button {
  @apply bg-neutral-300 rounded-xs flex items-center w-10 h-10 justify-center disabled:opacity-20;
}

.rotate-animation {
  @apply rotate-180 transition-transform;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
