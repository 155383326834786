import { ModifierDescriptor,
  ObserveEvent } from '@slabcode/hubster-modifiers-utils';

export function listenChildItemsChanges({
  modifiers,
  onChange,
}: {
  modifiers: ModifierDescriptor[];
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ObserveEvent<boolean> | ObserveEvent<number>) => void;
}): void {
  modifiers.forEach((m) => m.observeAllQuantities(onChange));
}
