import { AxiosInstance, AxiosResponse } from 'axios';

export function requestBuilder(instance: AxiosInstance) {
  const responseBody = (response: AxiosResponse<any>) => response.data;

  const requests = {
    get: (url: string, args = {}) => instance.get(url, args).then(responseBody),

    post: (url: string, body: object, args = {}) =>
      instance.post(url, body, args).then(responseBody),

    put: (url: string, body?: object, args = {}) =>
      instance.put(url, body, args).then(responseBody),

    patch: (url: string, body: object, args = {}) =>
      instance.patch(url, body, args).then(responseBody),

    delete: (url: string, args = {}) => instance.delete(url, args).then(responseBody),
  };

  return requests;
}
