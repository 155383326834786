import { ModifierDescriptor,
  ItemDescriptor } from '@slabcode/hubster-modifiers-utils';

export function getAutoSelectedModifiers(
  modifiers: ModifierDescriptor[],
): ItemDescriptor[] {
  return modifiers
    .map((m) => m.items.filter((item) => item.selected || item.quantity))
    .flat();
}
