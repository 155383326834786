<script setup lang="ts">
// TODO: Rename this component
const modalStore = useModalStore();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: '',
  },
  successButtonLabel: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  /**
   * Define if its an store state or local state
   */
  localModal: {
    type: Boolean,
    required: false,
    default: false,
  },
  denyButtonLabel: {
    type: String,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['onOk',
  /**
   * This emit is used when the modal has an localState instead of store state
   */
  'onClose']);

function closeModal() {
  if (!props.localModal) {
    modalStore.closeModal();
    return;
  }
  emit('onClose');
}

</script>
<template>
  <KioskModal>
    <template v-if="icon">
      <KioskImage
        :alt="title"
        :src="icon"
        image-class="w-[284px] h-auto"
        hide-border
      />
    </template>

    <h2 class="font-big uppercase my-6">
      {{ title }}
    </h2>

    <p v-if="message" class="modal-text my-14">
      {{ message }}
    </p>

    <div class="flex gap-5">
      <KioskButton
        text-size="small"
        @click="closeModal()"
      >
        {{ denyButtonLabel ? denyButtonLabel : $t('BACK') }}
      </KioskButton>

      <KioskButton
        color="primary"
        text-size="small"
        @click="$emit('onOk')"
      >
        {{ successButtonLabel }}
      </KioskButton>
    </div>
  </KioskModal>
</template>

<style scoped>
.modal-text {
  @apply text-3xl text-neutral-500 leading-8 tracking-tight text-center;
}
</style>
