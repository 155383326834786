<script lang="ts" setup>
import { KEYS } from '../constants/keyboardKeys';
import { KEYBOARD_EMAIL_SUGGESTIONS } from '../constants/keyboardEmailSuggestions';
import { SpecialKeys } from '../enums/specialKeys';

const metadataStore = useMetadataStore();
const activeNumeric = ref(false);

const { brandSettings } = storeToRefs(metadataStore);

const props = defineProps({
  showEmailSuggestions: {
    type: Boolean,
    default: true,
  },
  inputRef: {
    type: Object as PropType<HTMLInputElement | null>,
    required: true,
  },
});

const { inputRef } = toRefs(props);

const [model] = defineModel({
  required: true,
  type: String,
});

defineEmits(['close', 'accept', 'back']);

function updateValue(key: { normalValue: string; numericValue: string }) {
  if (key.normalValue === SpecialKeys.DELETE && inputRef.value) {
    model.value = keyboardDelete(inputRef.value, model.value);
    return;
  }

  if (!inputRef.value) return;
  inputRef.value.focus();

  if (key.normalValue === SpecialKeys.NUMERIC) {
    activeNumeric.value = !activeNumeric.value;
    return;
  }

  if (key.normalValue === SpecialKeys.SPACE) {
    model.value = keyboardAddLetter(inputRef.value, model.value, ' ');
    return;
  }

  if (activeNumeric.value) {
    model.value = keyboardAddLetter(inputRef.value, model.value, key.numericValue);
    return;
  }

  model.value = keyboardAddLetter(inputRef.value, model.value, key.normalValue);
}

function isSpecialKey(key: string) {
  return key === SpecialKeys.SPACE || key === SpecialKeys.DELETE || key === SpecialKeys.NUMERIC;
}

function acceptSuggestion(suggestion: string) {
  model.value += suggestion.toUpperCase();
}

</script>

<template>
  <div class="keyboard-container">
    <div class="flex items-center justify-center w-full">
      <slot name="input" />
      <div class="!w-1/12 close-button">
        <button type="button" @click="$emit('close')">
          <span class="text-5xl icon icon-arrow-down" />
        </button>
      </div>
    </div>
    <section class="keyboard-draw">
      <div class="suggestions">
        <template v-if="showEmailSuggestions">
          <button
            v-for="suggestion in KEYBOARD_EMAIL_SUGGESTIONS"
            class="suggestion-item"
            type="button"
            @click="acceptSuggestion(suggestion)"
            :key="suggestion"
          >
            {{ suggestion }}
          </button>
        </template>
      </div>
      <button
        type="button"
        v-for="[keyCode, keyValue] in Object.entries(KEYS)"
        :style="{
          gridArea: `${keyValue.keyName}`,
        }"
        :class="[
          brandSettings?.buttons.radius,
          'keyboard-key',
          {
            'special-key': isSpecialKey(keyValue.normalValue),
          },
        ]"
        :key="keyCode"
        @click="updateValue(keyValue)"
      >
        <span
          v-if="keyValue.normalValue === SpecialKeys.DELETE"
          class="text-6xl icon icon-delete"
        />
        <span
          v-if="keyValue.normalValue === SpecialKeys.SPACE"
          class="text-6xl icon icon-space_bar"
        />
        <span v-if="keyValue.normalValue !== SpecialKeys.SPACE && keyValue.normalValue !== SpecialKeys.DELETE">
          {{ activeNumeric ? keyValue.numericValue : keyValue.normalValue }}
        </span>
      </button>
      <KioskButton @click="$emit('accept')" color="primary" text-size="base" class="keyboard-key accept-btn">
        {{ $t('ACCEPT') }}
      </KioskButton>
    </section>
  </div>
</template>

<style scoped>
.keyboard-container {
  @apply w-full bg-gray-50 text-3xl z-50 px-5 py-5;
}

.keyboard-draw {
  @apply grid gap-x-2 gap-y-4 w-full place-items-center px-5 pb-20 py-5 bg-gray-50 text-3xl z-50;
  grid-template-areas:
  "sug sug sug sug sug sug sug sug sug sug"
  "q-key w-key e-key r-key t-key y-key u-key i-key o-key p-key"
  "a-key s-key d-key f-key g-key h-key j-key k-key l-key ñ-key"
  "num-modifier-key z-key x-key c-key v-key b-key n-key m-key delete-key delete-key"
  ". . space-key space-key space-key space-key space-key space-key accept-btn accept-btn";
}

.close-button {
  @apply h-5 w-full text-center;
  grid-area: close;
}

.suggestions {
  @apply flex justify-between w-full text-4xl py-4 px-8;
  grid-area: sug;
}

.suggestion-item {
  @apply w-full text-center border-r-2;
}

.suggestion-item:last-child {
  @apply border-r-0;
}

.keyboard-key {
  @apply w-24 h-24 bg-white shadow-lg border rounded-md flex items-center justify-center;
}

.keyboard-key:active{
  @apply bg-gray-200;
}

.special-key {
  @apply w-full bg-gray-200;
}

.accept-btn {
  @apply w-full;
  grid-area: accept-btn;
}
</style>
