<script lang="ts" setup>
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { useMutationObserver, useScroll } from '@vueuse/core';
import { goBackToHome } from '@/common/utils';
import { RouteName } from '@/common/routes/routeName';
import { FadeColors } from '@/common/enums/fadeColors';

const galleryRef = ref<HTMLElement | null>(null);

const route = useRoute();
const router = useRouter();
const cartStore = useCartStoreV2();
const menuStore = useMenuStore();
const modalStore = useModalStore();
const metadataStore = useMetadataStore();
const settingsStore = useSettingsStore();
const total = useTotal();

const { arrivedState, measure } = useScroll(galleryRef);

const { colors } = storeToRefs(metadataStore);
const { currentCategory, categories } = storeToRefs(menuStore);
const { triggerCancelOrder, triggerViewItemList } = useGTMEventsComposableV2();

useMutationObserver(galleryRef, () => {
  measure();
}, {
  childList: true,
});

function cancelOrder() {
  triggerCancelOrder({
    items: toGTMCartItems(cartStore.items),
    section: route.name!.toString(),
    isModal: false,
  });

  cartStore.clearCart();
  modalStore.closeModal();

  if (settingsStore.multibrandData) {
    const { cancelUrl } = settingsStore.multibrandData;
    window.location.href = cancelUrl;
    return;
  }

  goBackToHome();
}

function selectCategory(category: HubsterCategory) {
  menuStore.selectCategory(category.id);
  // Trigger GTM event
  triggerViewItemList(category);
}

function goCart() {
  router.push({ name: RouteName.CART });
}
</script>

<template>
  <section
    class="layout-container full-hd"
    v-if="currentCategory"
    :style="{ background: colors?.background }"
  >
    <div class="categories-menu">
      <OrderMenu
        :menu-items="categories"
        :selected-category="currentCategory"
        @select-category="selectCategory($event)"
      />
    </div>
    <ScrollContainerV2 class="gallery" :top="arrivedState.top" :bottom="arrivedState.bottom" :color="FadeColors.BACKGROUND">
      <div ref="galleryRef" class="h-full overflow-y-auto">
        <OrderGallery
          :product-items="menuStore.products"
          :category="currentCategory"
        />
      </div>
    </ScrollContainerV2>

    <div class="cart-actions">
      <CartActions :disabled="cartStore.subtotal === 0" :action-lbl="$t('TIMELINE.CONFIRM')" @next="goCart()" @back="cancelOrder()">
        <template #price>
          {{ total.getFormatPrice(cartStore.subtotal) }}
        </template>
      </CartActions>
    </div>
  </section>
  <KioskLoading v-else />

  <ConfirmModal
    v-if="modalStore.showModal"
    modal-class="!w-[588px]"
    :title="$t('CANCEL_ORDER.QUESTION')"
    :message="$t('CANCEL_ORDER.ADVICE')"
    :success-button-label="$t('CANCEL_ORDER.CONFIRMATION')"
    @on-ok="cancelOrder()"
  />
</template>

<style scoped>
.layout-container {
  @apply grid;
  grid-template-areas:
   "mn gl gl"
   "mn gl gl"
   "mn gl gl"
   "ft ft ft";
}

.categories-menu {
  @apply h-full;
  grid-area: mn;
}

.gallery {
  @apply overflow-y-hidden relative;
  grid-area: gl;
}

.cart-actions {
  @apply place-content-end;
  grid-area: ft;
}
</style>
