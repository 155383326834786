<template>
  <section class="component-wrapper full-hd">
    <figure class="flex flex-col items-center gap-4">
      <img src="/src/assets/images/404robot.gif" alt="404 robot" />
      <figcaption class="flex flex-col items-center gap-4 w-[675px] text-center text-4xl tracking-tight">
        <h1 class="text-5xl font-bold leading-6 tracking-tight uppercase text-neutral-500">
          Error 404
        </h1>
        <p>
          No pudimos encontrar la página que buscas. ¡No te preocupes! Solo
          necesitas <strong>configurar el ID de tienda del kiosko</strong> y estarás en marcha en
          un abrir y cerrar de ojos. 😊
        </p>
      </figcaption>
    </figure>
  </section>
</template>

<style scoped>
.component-wrapper {
  @apply bg-white flex flex-col justify-center items-center;
}
</style>
