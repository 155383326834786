import { createRootItem,
  ItemDescriptor,
  quantifiable } from '@slabcode/hubster-modifiers-utils';
import { v4 as uuidv4 } from 'uuid';
import { ICartState } from '../interfaces/cartState';
import { itemTaxes } from '../utils/itemSubtotal';
import { ArrayElement } from '@/common/types/arrayType';

const INITIAL_STATE: ICartState = {
  items: [],
};

export const useCartStoreV2 = defineStore('cartV2', {
  state: () => ({ ...INITIAL_STATE }),
  actions: {
    addItem(item: ItemDescriptor, index?: number): ArrayElement<ICartState['items']> {
      const toAdd = {
        item,
        description: itemDescription(item),
        identifier: uuidv4(),
      };
      if (typeof index === 'number') {
        this.items.splice(index, 0, toAdd);
        return toAdd;
      }
      this.items = [...this.items, toAdd];
      return toAdd;
    },
    addItemWithoutCustomization(itemId: string, index?: number): ArrayElement<ICartState['items']> {
      const itemOnCart = this.items.find(
        (_item) => _item.item.itemMeta.id === itemId,
      );
      if (itemOnCart && quantifiable(itemOnCart.item.actions)) {
        itemOnCart.item.actions.increase();
        this.updateCart();
        return itemOnCart;
      }
      const item = createRootItem({ itemId });
      return this.addItem(item, index);
    },
    increaseItemQuantity(item: ItemDescriptor) {
      if (quantifiable(item.actions)) {
        item.actions.increase();
      }
      this.updateCart();
    },
    decreaseItemQuantity(item: ItemDescriptor) {
      if (quantifiable(item.actions)) {
        item.actions.decrease();
      }
      if (item.quantity === 0) {
        const itemIndex = this.items.findIndex((_item) => _item.item === item);
        this.items.splice(itemIndex, 1);
      }
      this.updateCart();
    },
    removeItem(item: ItemDescriptor) {
      const itemIndex = this.items.findIndex((_item) => _item.item === item);
      this.items.splice(itemIndex, 1);
      this.updateCart();
    },
    updateItemDescription(itemIndex: number) {
      const { item } = this.items[itemIndex];
      this.items[itemIndex].description = itemDescription(item);
      this.updateCart();
    },
    updateCart() {
      this.items = [...this.items];
    },
    clearCart() {
      this.items = [];
    },
  },
  getters: {
    itemsCount: (state) =>
      addLeftZero(
        state.items.reduce((total, item) => item.item.quantity + total, 0),
      ),
    itemCartCount: (state) => (itemId: string) =>
      state.items
        .filter((item) => item.item.itemMeta.id === itemId)
        .reduce((total, item) => item.item.quantity + total, 0),
    subtotal: (state) =>
      state.items.reduce((total, item) => item.item.total + total, 0),
    taxes: (state) =>
      state.items.reduce((taxes, item) => taxes + itemTaxes(item.item), 0),
  },
});
