import { rest } from 'msw';

export const updatesRequests = (msw: typeof rest) => [
  msw.get(':storeId/metadata', async (_, res, ctx) => res(
    ctx.status(200),
    ctx.delay(1000),
    ctx.json(DYNAMIC_DATA),
  )),

  msw.get(':storeId/metadata/static', async (_, res, ctx) => res(
    ctx.status(200),
    ctx.delay(500),
    ctx.json(STATIC_DATA),
  )),
];

// export default updatesRequests;
