<script lang="ts" setup>
defineProps({
  backButtonText: {
    type: String,
    required: true,
  },
  hideCarousel: {
    type: Boolean,
    required: false,
    default: false,
  },
});

defineEmits(['goBack']);

const metadataStore = useMetadataStore();
</script>

<template>
  <section class="flex flex-col items-center gap-14 w-[560px]">
    <KioskButton
      color="ghost"
      text-size="medium"
      class="!shadow-none"
      @click="$emit('goBack')"
    >
      <span
        class="block mr-4 icon"
        :class="`icon-redo`"
      />

      {{ backButtonText }}
    </KioskButton>

    <div
      v-if="!hideCarousel"
      class="flex items-center justify-center"
    >
      <KioskImage image-class="!border-none" class="h-[420px] w-[420px]" :src="metadataStore.carouselImage ?? ''" alt="carousel image" />
    </div>

    <slot />
  </section>
</template>
