/* eslint-disable no-unused-vars */
export enum JobStatus {
  PROCESSING = 1,
  PAYMENT_SUCCEEDED = 2,
  PAYMENT_FAILED = 3,
  PAYMENT_IGNORE = 4,
  INJECTION_SUCCEEDED = 5,
  INJECTION_FAILED = 6,
  INJECTION_IGNORE = 7,
  UNKNOWN_ERROR = 10,
}
